import { createSelector } from 'reselect'

export const stateSelector = state =>
  state.api.get.all.mentoring_relationships_feedbacks

export const data = () => createSelector([stateSelector], state => state.data)

export const currentFeedbackMentoringRelationship = currentProfile =>
  createSelector(
    [data(), currentProfile],
    (mentoringRelationShipFeedback, currentProfile) =>
      (mentoringRelationShipFeedback &&
        mentoringRelationShipFeedback.find(
          feedback => feedback.attributes.feedbackable_id === currentProfile.id
        )) ||
      null
  )

export const isLoading = () =>
  createSelector([stateSelector], state => state.isLoading)
