import { createSelector } from 'reselect'

export const stateSelector = state => state.api.post.one.conversations_comments

export const data = () =>
  createSelector(
    [stateSelector],
    commentsState => {
      if (commentsState.data) {
        return commentsState.data
      }
      return null
    }
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    commentsState => commentsState.isLoading
  )
