/* eslint-disable no-nested-ternary */
import { createSelector } from 'reselect'

export const stateSelector = state => state.api.get.all.mentoring_relationships

export const data = () =>
  createSelector(
    [stateSelector],
    mentoringRequestsState => mentoringRequestsState.data
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    mentoringRequestState => mentoringRequestState.isLoading
  )

export const mentoringRequestedSelector = () =>
  createSelector(
    [data()],
    mentoringRequestsData =>
      mentoringRequestsData &&
      mentoringRequestsData.filter(
        mentoringRequest =>
          mentoringRequest.attributes.aasm_state === 'requested'
      )
  )

export const mentoringConfirmedSelector = () =>
  createSelector(
    [data()],
    mentoringRequestsData =>
      mentoringRequestsData &&
      mentoringRequestsData.filter(
        mentoringRequest =>
          mentoringRequest.attributes.aasm_state === 'initiated' ||
          mentoringRequest.attributes.aasm_state === 'admin_initiated'
      )
  )

export const mentoringFinishedSelector = () =>
  createSelector(
    [data()],
    mentoringRequestsData =>
      mentoringRequestsData &&
      mentoringRequestsData.filter(
        mentoringRequest =>
          mentoringRequest.attributes.aasm_state === 'finished' ||
          mentoringRequest.attributes.aasm_state === 'feedback_provided' ||
          mentoringRequest.attributes.aasm_state === 'admin_finished'
      )
  )

export const mentoringFeedbackProvidedSelector = () =>
  createSelector(
    [data()],
    mentoringRequestsData =>
      mentoringRequestsData &&
      mentoringRequestsData.filter(
        mentoringRequest =>
          mentoringRequest.attributes.aasm_state === 'feedback_provided'
      )
  )

export const mentoringCancelledSelector = () =>
  createSelector(
    [data()],
    mentoringRequestsData =>
      mentoringRequestsData &&
      mentoringRequestsData.filter(
        mentoringRequest =>
          mentoringRequest.attributes.aasm_state === 'cancelled'
      )
  )

export const requestedIds = currentProfileIsPresenter =>
  createSelector(
    [mentoringRequestedSelector(), currentProfileIsPresenter],
    (mentoringRequestsData, isCurrentUserPresenter) => {
      if (mentoringRequestsData) {
        return mentoringRequestsData.reduce((acc, profile) => {
          acc.push(
            isCurrentUserPresenter
              ? profile.relationships.participant.data
                ? profile.relationships.participant.data.id
                : null
              : profile.relationships.presenter.data
              ? profile.relationships.presenter.data.id
              : null
          )

          return acc
        }, [])
      }
      return null
    }
  )

export const confirmedIds = currentProfileIsPresenter =>
  createSelector(
    [mentoringConfirmedSelector(), currentProfileIsPresenter],
    (mentoringRequestsData, isCurrentUserPresenter) => {
      if (mentoringRequestsData) {
        return mentoringRequestsData.reduce((acc, profile) => {
          acc.push(
            isCurrentUserPresenter
              ? profile.relationships.participant.data
                ? profile.relationships.participant.data.id
                : null
              : profile.relationships.presenter.data
              ? profile.relationships.presenter.data.id
              : null
          )
          return acc
        }, [])
      }
      return null
    }
  )

export const cancelledIds = currentProfileIsPresenter =>
  createSelector(
    [mentoringCancelledSelector(), currentProfileIsPresenter],
    (mentoringRequestsData, isCurrentUserPresenter) => {
      if (mentoringRequestsData) {
        return mentoringRequestsData.reduce((acc, profile) => {
          acc.push(
            isCurrentUserPresenter
              ? profile.relationships.participant.data
                ? profile.relationships.participant.data.id
                : null
              : profile.relationships.presenter.data
              ? profile.relationships.presenter.data.id
              : null
          )
          return acc
        }, [])
      }
      return null
    }
  )
