import { createSelector } from 'reselect'

export const stateSelector = state => state.api.post.one.articles

export const data = () =>
  createSelector(
    [stateSelector],
    articleState => {
      if (articleState.data) {
        return articleState.data
      }
      return null
    }
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    articleState => articleState.isLoading
  )
