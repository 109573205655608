import { createSelector } from 'reselect'

export const presenterStateSelector = state => state.api.get.all.presenter_steps

export const participantStateSelector = state =>
  state.api.get.all.participant_steps

export const presenterStepsData = () =>
  createSelector([presenterStateSelector], presenterSteps => {
    if (presenterSteps.data) {
      return presenterSteps.data
    }
    return null
  })

export const presenterStepsIsLoading = () =>
  createSelector(
    [presenterStateSelector],
    presenterStepsState => presenterStepsState.isLoading
  )

export const participantStepsData = () =>
  createSelector([participantStateSelector], participantSteps => {
    if (participantSteps.data) {
      return participantSteps.data
    }
    return null
  })

export const participantStepsIsLoading = () =>
  createSelector(
    [participantStateSelector],
    participantStepsState => participantStepsState.isLoading
  )

/**
 * Select all steps corresponding to the profile type of the user
 * (participant or presenter)
 */
export const stepsDataSelector = (state, props) =>
  state.api.get.all[`${props.currentProfileItem.type}_steps`].data.sort(
    (first, second) => first.attributes.order - second.attributes.order
  )

export const stepsDataSelectorParams = currentUserProfileData =>
  createSelector(
    [currentUserProfileData, participantStateSelector, presenterStateSelector],
    (profileData, participantSteps, presenterSteps) => {
      if (profileData) {
        const { type } = profileData

        if (type === 'presenters') {
          return presenterSteps.data
        }
        if (type === 'participants') {
          return participantSteps.data
        }
        return null
      }
      return null
    }
  )

/**
 * Select the prop step from the component (Stepper). This make us constrained
 * to pass it from it's parent component.
 */
export const anchorPropsSelector = (_, props) =>
  props.step /* step is the string anchor retrieved from url params */

export const paramsPropsSelector = (_, props) => props.match.params

export const findFirstStepsAnchorsNames = () =>
  createSelector(
    [presenterStateSelector, participantStateSelector],
    (presenterSteps, participantSteps) => {
      if (presenterSteps.data && participantSteps.data) {
        const firstPresenterAnchor =
          presenterSteps.data.find(step => step.attributes.order === 1) &&
          presenterSteps.data.find(step => step.attributes.order === 1)
            .attributes.anchor
        const firstParticipantAnchor =
          participantSteps.data.find(step => step.attributes.order === 1) &&
          participantSteps.data.find(step => step.attributes.order === 1)
            .attributes.anchor

        return {
          presenter: firstPresenterAnchor,
          participant: firstParticipantAnchor
        }
      }
      return null
    }
  )

/**
 * Return the step corresponding to an anchor value
 */
export const findStepByAnchor = () =>
  createSelector(
    [stepsDataSelector, anchorPropsSelector],
    (profileSteps, anchor) =>
      profileSteps.find(step => step.attributes.anchor === anchor)
  )

/**
 * Return the step corresponding to an anchor value
 */
export const findStepByParams = stepsData =>
  createSelector([stepsData, paramsPropsSelector], (steps, params) => {
    if (params && steps) {
      const stepByAnchor = steps.find(
        step => step.attributes.anchor === params.anchor
      )

      if (stepByAnchor) {
        return stepByAnchor
      }
      return steps[0]
    }
    return null
  })

/**
 * Return an Array of all the steps's anchors string
 */
export const allAnchorsNamesStepsAndTitles = () =>
  createSelector([stepsDataSelector], steps =>
    steps.reduce((acc, currentStep) => {
      acc.push({
        anchor: currentStep.attributes.anchor,
        title: currentStep.attributes.title
      })
      return acc
    }, [])
  )

export const allAnchorsNamesStepsAndTitlesParams = stepsData =>
  createSelector([stepsData], steps => {
    if (steps) {
      return steps.reduce((acc, currentStep) => {
        acc.push({
          anchor: currentStep.attributes.anchor,
          title: currentStep.attributes.title,
          order: currentStep.attributes.order
        })
        return acc
      }, [])
    }
    return null
  })

/**
 * Utils for selectors.
 */
export const formatOrder = (step, anchorsAndTitles, rest) => {
  const order = step.attributes.order + rest.direction

  if (order <= 0) {
    return 1
  }
  if (order > anchorsAndTitles.length) {
    return anchorsAndTitles.order
  }
  return order
}

/**
 * Find previous step to find previous anchor name
 */
export const findPreviousStepByOrder = () =>
  createSelector(
    [stepsDataSelector, findStepByAnchor(), allAnchorsNamesStepsAndTitles()],
    (steps, actualStep, arrayAnchorsTitles) =>
      steps.find(
        step =>
          step.attributes.order ===
          formatOrder(actualStep, arrayAnchorsTitles, { direction: -1 })
      )
  )

export const findPreviousAnchorNameParams = stepsData =>
  createSelector(
    [
      stepsData,
      findStepByParams(stepsData),
      allAnchorsNamesStepsAndTitlesParams(stepsData)
    ],
    (steps, actualStep, arrayAnchorsTitles) => {
      if (steps && actualStep && arrayAnchorsTitles) {
        const currentStep = steps.indexOf(actualStep)
        const prevStep = (currentStep - 1) % steps.length
        const previousAnchorName =
          prevStep > -1 ? steps[prevStep].attributes.anchor : null

        return previousAnchorName
      }
      return null
    }
  )

/**
 * Find previous step anchor name
 */
export const findPreviousAnchorName = () =>
  createSelector(
    findPreviousStepByOrder(),
    previousStep => previousStep.attributes.anchor
  )

/**
 * Find next step to find next anchor name
 */
export const findNextStepByOrder = () =>
  createSelector(
    [stepsDataSelector, findStepByAnchor(), allAnchorsNamesStepsAndTitles()],
    (steps, actualStep, arrayAnchorsTitles) =>
      steps.find(
        step =>
          step.attributes.order ===
          formatOrder(actualStep, arrayAnchorsTitles, { direction: 1 })
      )
  )

export const findNextAnchorNameParams = stepsData =>
  createSelector(
    [
      stepsData,
      findStepByParams(stepsData),
      allAnchorsNamesStepsAndTitlesParams(stepsData)
    ],
    (steps, actualStep, arrayAnchorsTitles) => {
      if (steps && actualStep && arrayAnchorsTitles) {
        const currentStepIndex = steps.indexOf(actualStep)
        const nextStepIndex =
          currentStepIndex + 1 < steps.length && currentStepIndex + 1
        const nextAnchorName = nextStepIndex
          ? steps[nextStepIndex].attributes.anchor
          : null

        return nextAnchorName
      }
      return null
    }
  )

/**
 * Find next step anchor name
 */
export const findNextAnchorName = () =>
  createSelector(findNextStepByOrder(), nextStep => nextStep.attributes.anchor)

export const findAnchorNameFromProfileNextStep = profileNextStepSelector =>
  createSelector(
    [stepsDataSelector, profileNextStepSelector],
    (steps, nextStep) => {
      let anchorFound = null

      if (steps) {
        steps.map(step => {
          step.relationships.questions.data.map(question => {
            if (question.attributes.field_name === nextStep) {
              anchorFound = step.attributes.anchor
            }
            return null
          })
          return null
        })
      }
      return anchorFound
    }
  )

export const findAllProfileQuestions = () =>
  createSelector([stepsDataSelector], stepsData => {
    if (stepsData) {
      return stepsData.reduce((acc, step) => {
        step.relationships.questions.data.forEach(question => {
          acc.push({
            ...question,
            attributes: {
              ...question.attributes,
              anchor_step: step.attributes.anchor
            }
          })
        })
        return acc
      }, [])
    }
    return null
  })

export const findAllQuestionsCategoryProfileQuestion = () =>
  createSelector([stepsDataSelector], stepsData => {
    if (stepsData) {
      return stepsData.reduce((acc, step) => {
        step.relationships.questions.data.forEach(question => {
          if (
            (question.attributes.category === 'profile_question' ||
              question.attributes.category === 'user_question') &&
            question.attributes.field_type !== 'tag' &&
            question.attributes.field_type !== 'input'
          ) {
            acc.push({
              ...question,
              attributes: {
                ...question.attributes,
                anchor_step: step.attributes.anchor
              }
            })
          }
        })
        return acc
      }, [])
    }
    return null
  })

export const findAllQuestionsCategoryUserQuestion = () =>
  createSelector([stepsDataSelector], stepsData => {
    if (stepsData) {
      return stepsData.reduce((acc, step) => {
        step.relationships.questions.data.forEach(question => {
          if (question.attributes.category === 'user_question') {
            acc.push({
              ...question,
              attributes: {
                ...question.attributes,
                anchor_step: step.attributes.anchor
              }
            })
          }
        })
        return acc
      }, [])
    }
    return null
  })

export const filterByCategoryProfileQuestion = () =>
  createSelector([stepsDataSelector], stepsData => {
    if (stepsData) {
      const filterStepsData = stepsData.filter(step => {
        const { questions } = step.relationships

        return questions.some(
          question => question.attributes.category === 'profile_question'
        )
      })

      return filterStepsData
    }
    return null
  })
