import { createSelector } from 'reselect'

export const stateSelector = state => state.api.get.all.meeting_reasons

export const data = () =>
  createSelector(
    [stateSelector],
    meetingReasonsState => meetingReasonsState.data
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    meetingReasonsState => meetingReasonsState.isLoading
  )
