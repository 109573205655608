import { createSelector } from 'reselect'

export const stateSelector = state => state.api.get.all.availabilities
export const weekDayProp = (_, props) => props.weekDay

export const data = () =>
  createSelector(
    [stateSelector],
    availabilities => {
      if (availabilities.data) {
        return availabilities.data.sort(
          (first, second) =>
            first.attributes.weekday - second.attributes.weekday
        )
      }
      return null
    }
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    availabilities => availabilities.isLoading
  )

export const weekDay = () =>
  createSelector(
    [data(), weekDayProp],
    (availabilities, weekDayNumber) => {
      if (availabilities && weekDayNumber) {
        return availabilities
          .filter(
            availability => availability.attributes.weekday === weekDayNumber
          )
          .filter(
            availability =>
              availability.attributes.start_time > 6 &&
              availability.attributes.end_time < 23
          )
          .sort(
            (first, second) =>
              first.attributes.start_time - second.attributes.start_time
          )
      }
      return null
    }
  )

export const weekDays = () =>
  createSelector(
    [data()],
    availabilities => {
      if (availabilities) {
        const WEEK_DAYS = [
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'saturday',
          'sunday'
        ]

        return availabilities.reduce((acc, availability) => {
          const weekName = WEEK_DAYS[availability.attributes.weekday - 1]

          if (!acc.find(item => item.name === weekName)) {
            acc.push({
              name: weekName,
              number: availability.attributes.weekday
            })
          }
          return acc
        }, [])
      }
      return null
    }
  )
