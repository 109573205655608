import { createSelector } from 'reselect'

export const stateSelector = state => state.api.post.one.sessions

export const data = () =>
  createSelector(
    [stateSelector],
    sessions => sessions.data
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    sessions => sessions.isLoading
  )
