import Enzyme from '..'
import Requests from '../Requests'

// eslint-disable-next-line import/no-anonymous-default-export
export default store => next => action => {
  const { prefix, suffix } = Enzyme.config
  const { before: beforeRequestPrefix } = prefix.request
  const { before: beforeRequestSuffix } = suffix.request

  if (
    action.type.includes(beforeRequestPrefix) &&
    action.type.includes(beforeRequestSuffix)
  ) {
    const { storedFrom } = action
    const validType = Enzyme.config.typesRequests.find(
      requestType => requestType === storedFrom.type
    )

    if (!validType) {
      throw new Error(
        `Request type ${storedFrom.type} is not reconized by the Request middleware. All action abordted`
      )
    }

    const asyncActionsRequest = async () => {
      /**
       * Refresh headers before each request
       */
      if (
        Enzyme.config.changeHeadersOnEachRequest &&
        Enzyme.config.forceRefreshHeadersBeforeRequest
      ) {
        Enzyme.refreshHeadersFromStorage()
      }
      /**
       * Dispatch the action in retention => dispatchBefore()
       * Because we wanted the store to update with the dispatchBefore action
       * And store this instance of the store in an object
       */
      const newState = next(action)

      /**
       * Replace the output/returned value with the axios event promise
       */
      return new Enzyme()
        [storedFrom.type](
          storedFrom.path(),
          storedFrom.data,
          storedFrom.params,
          storedFrom.axios
        )
        .then(response => {
          /**
           * Valide response based on the objectRequest.axios.validateStatus callback
           * Seed axios docs for more details on caching OK request and non-OK request
           * And too handle fine error in non-OK request
           */
          /**
           * Change headers on each request
           */
          if (Enzyme.config.changeHeadersOnEachRequest && response.headers) {
            Enzyme.setHeaders(response.headers)
          }
          /**
           * Send the dispatchSuccess callback with dataPointer callback and newState in argument
           */
          Enzyme.store.dispatch(
            storedFrom.dispatchSuccess(
              storedFrom.dataPointer(response),
              newState
            )
          )
          /**
           * Update another state if dispatchAfterSuccessUpdate is array or object
           */
          if (storedFrom.dispatchAfterSuccessUpdate) {
            if (Array.isArray(storedFrom.dispatchAfterSuccessUpdate)) {
              storedFrom.dispatchAfterSuccessUpdate.forEach(updateState => {
                Enzyme.store.dispatch(
                  updateState(storedFrom.dataPointer(response))
                )
              })
            } else {
              Enzyme.store.dispatch(
                storedFrom.dispatchAfterSuccessUpdate(
                  storedFrom.dataPointer(response)
                )
              )
            }
          }

          /**
           * Delete another state if dispatchAfterSuccessDelete is array or object
           */
          if (storedFrom.dispatchAfterSuccessDelete) {
            if (Array.isArray(storedFrom.dispatchAfterSuccessDelete)) {
              storedFrom.dispatchAfterSuccessDelete.forEach(deleteState => {
                Enzyme.store.dispatch(
                  deleteState(storedFrom.dataPointer(response))
                )
              })
            } else {
              Enzyme.store.dispatch(
                storedFrom.dispatchAfterSuccessDelete(
                  storedFrom.dataPointer(response)
                )
              )
            }
          }

          /**
           * Send notifications to the client ui if notifications key
           * TODO : look if we dispatch from this store => better performance result in profiling devtools
           */
          if (storedFrom.notifications) {
            new Enzyme.plugins.Notifications({
              response,
              ...storedFrom.notifications
            }).send()
          }

          /**
           * return a promise because we wanted to be able to .then.catch.finally in component
           */
          return Promise.resolve(response)
        })
        .catch(error => {
          /**
           * Error Manager - based on axios doc
           * TODO: export in a class
           */
          if (error.response) {
            if (
              Enzyme.config.changeHeadersOnEachRequest &&
              error.response.headers
            ) {
              Enzyme.setHeaders(error.response.headers)
            }
            /**
             * TODO :
             * - Add responseFailedPointer
             * - rename dataPointer into responseSuccessPointer
             * The request was made and the server responded with a status code
             * that falls out of the range of validateStatus callback in axios config
             */
            Enzyme.store.dispatch(
              storedFrom.dispatchFailed(error.response.data, newState)
            )
            /**
             * Send notifications to the client ui if notifications key
             */
            if (storedFrom.notifications) {
              new Enzyme.plugins.Notifications({
                response: error.response,
                ...storedFrom.notifications
              }).send()
            }
            /**
             * Delete store if 401. that will redirect automaticaly from router to Login
             */
            if (error.response.status === 401 && Enzyme.config.onUnauthorized) {
              Enzyme.config.onUnauthorized(Requests)
            }
            return Promise.reject(error.response)
          }
          if (error.request) {
            /**
             * The request was made but no response was received in time
             * `error.request` is an instance of XMLHttpRequest
             */
            Enzyme.store.dispatch(
              storedFrom.dispatchFailed(error.request, newState)
            )

            /**
             * Send notifications to the client ui if notifications key
             */
            if (storedFrom.notifications) {
              new Enzyme.plugins.Notifications({
                response: {
                  status: error.request.status,
                  message: error.message
                },
                ...storedFrom.notifications
              }).send()
            }
            return Promise.reject(error.request)
          }
          /**
           * TODO: make specific action creators and dispatch action here
           * Something happened in setting up the request that triggered an Error
           */
          store.dispatch(storedFrom.dispatchFailed(error.message, newState))
          return Promise.reject(error.message)
        })
    }

    return store.dispatch(asyncActionsRequest)
  }
  return next(action)
}
