import { createSelector } from 'reselect'

export const stateSelector = state => state.api.patch.one.profile

export const data = () =>
  createSelector(
    [stateSelector],
    oneProfileState => {
      if (oneProfileState.data) {
        return oneProfileState.data
      }
      return null
    }
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    oneProfileState => oneProfileState.isLoading
  )
