import { createSelector } from 'reselect'

const stateSelector = state => state.api.get.paginated.presenters

export const data = () =>
  createSelector(
    [stateSelector],
    paginatedPresenters => paginatedPresenters.data
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    paginatedPresenters => paginatedPresenters.isLoading
  )

