import { createSelector } from 'reselect'

export const stateSelector = state =>
  state.api.patch.one.presenter_recommendations

export const data = () =>
  createSelector([stateSelector], patchOneRecommendationState => {
    if (patchOneRecommendationState.data) {
      return patchOneRecommendationState.data
    }
    return null
  })

export const isLoading = () =>
  createSelector(
    [stateSelector],
    patchOneRecommendationState => patchOneRecommendationState.isLoading
  )
