import { createSelector } from 'reselect'

/**
 * Request|Resources state selector
 */
const stateSelector = state => state.api.get.all.profile_questions

/**
 * Generic selectors
 */
export const data = () =>
  createSelector(
    [stateSelector],
    profileQuestionsData => profileQuestionsData.data
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    profileQuestionsData => profileQuestionsData.isLoading
  )

/**
 * Props selectors
 */
export const fieldNamePropsSelector = (_, props) => props.fieldName

/**
 * Others selectors
 */
export const questionByFieldName = () =>
  createSelector(
    [data(), fieldNamePropsSelector],
    (profileQuestionsData, fieldName) => {
      if (profileQuestionsData && fieldName) {
        return profileQuestionsData.find(
          question => question.attributes.field_name === fieldName
        )
      }
      return null
    }
  )

export const questionTitleAndAnswersBody = () =>
  createSelector(
    [questionByFieldName()],
    questionSelected => {
      if (questionSelected) {
        // eslint-disable-next-line max-len
        const answerBodys = questionSelected.relationships.profile_answers.data.reduce(
          (allBodyAnswersArray, currentAnswer) => {
            allBodyAnswersArray.push(currentAnswer.attributes)
            return allBodyAnswersArray
          },
          []
        )
        const { title } = questionSelected.attributes

        return {
          answer_body: answerBodys,
          title
        }
      }
      return null
    }
  )

export const allFieldNamesArray = () =>
  createSelector(
    [data()],
    profileQuestionsData => {
      if (profileQuestionsData) {
        return profileQuestionsData.reduce((acc, question) => {
          acc.push(question.attributes.field_name)
          return acc
        }, [])
      }
      return null
    }
  )
