import { createSelector } from 'reselect'

export const stateSelector = state => state.api.post.one.meetings_appointments

export const data = () =>
  createSelector(
    [stateSelector],
    postOneMeetingAppointmentState => {
      if (postOneMeetingAppointmentState.data) {
        return postOneMeetingAppointmentState.data
      }
      return null
    }
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    postOneMeetingAppointmentState => postOneMeetingAppointmentState.isLoading
  )
