import Enzyme from '..'

/**
 * Actions creators
 * @class to make dry actions and combine them
 */
export default class ActionCreators {
  /**
   * Create Request Action
   * @param {Object} request - An Object
   * @param {String} request.type - type of the request
   * @param {Function} request.path - path of the request
   * @param {Function} request.dataPointer - Pointer to the request response to catch desired data
   * @param {Object} [request.data] - data to be send in the body of the request
   * @param {Object} [request.params] - params to be send as query url
   * @param {Function} [request.dispatchBefore] - action to be dispatch before the request
   * @param {Function} [request.dispatchSuccess] - action to be dispatched on request success
   * @param {Function} [request.dispatchFailed] - action to be dispatched on request failed
   * @param {Function} [request.dispatchAfter] - action to be dispatched after the request
   * @returns {Promise<object>} A promise
   */
  // const state = Api.store.getState().api[requestObject.type][requestObject.subType][requestObject.model]
  // const now = Date.now()
  // const defaultMinTimestamp =
  //   requestObject.timestamp && Number.isInteger(requestObject.timestamp)
  //     ? requestObject.timestamp + state.requestSpeed || 0
  //     : Api.config.default.request.timestamp + state.requestSpeed || false

  // console.log(defaultMinTimestamp, '<', now - state.lastUpdated)
  // if (defaultMinTimestamp && !state.errors && now - state.lastUpdated < defaultMinTimestamp && state.isLoading) {
  //   Api.store.dispatch({ type: 'ON FAIT PAS LA REQUEST', delay: now - state.lastUpdated })
  //   return false
  // }
  static async sendRequestActions(requestObject) {
    return Enzyme.store.dispatch(requestObject.dispatchBefore(requestObject))
  }

  static validRequestActions(requestObject) {
    return Enzyme.store.dispatch(
      requestObject.dispatchCriticalValidationsValid()
    )
    /** don't return value cause no need ! */
  }

  static invalidRequestActions(requestObject) {
    Enzyme.store.dispatch(requestObject.dispatchCriticalValidationsInvalid())
    /** return false to make request promise returned value to false
     * This is better to know if request validations failed in Promise.all.then()
     */
    return false
  }
}
