import { createSelector } from 'reselect'

export const stateSelector = state => state.api.get.one.mentoring_relationships

export const data = () => createSelector([stateSelector], state => state.data)

export const isLoading = () =>
  createSelector([stateSelector], state => state.isLoading)

export const feedbacks = () =>
  createSelector([data()], mentoringRelationships => {
    if (mentoringRelationships) {
      return mentoringRelationships.relationships.feedbacks
    }
    return null
  })
