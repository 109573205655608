import { createSelector } from 'reselect'

export const stateSelector = state => state.api.get.all.conversations
export const idPropSelector = (_, props) => props.id
export const roleUserIdPropSelector = (state, userInfo) => {
  return userInfo.attributes.role_user_id
}

export const data = () =>
  createSelector(
    [stateSelector],
    conversationsState => conversationsState.data
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    conversationstate => conversationstate.isLoading
  )

export const conversationById = () =>
  createSelector(
    [data(), idPropSelector],
    (conversationsData, idConversation) => {
      if (conversationsData && idConversation) {
        return conversationsData.find(
          conversation => conversation.id === idConversation
        )
      }
      return null
    }
  )

export const conversationByRoleUserId = () =>
  createSelector(
    [data(), roleUserIdPropSelector],
    (conversationsData, roleUserId) => {
      if (conversationsData && roleUserId) {
        const conv = conversationsData.find(
          conversation =>
            conversation.relationships.sender.data.id === roleUserId ||
            conversation.relationships.receiver.data.id === roleUserId
        )

        if (conv) {
          return conv
        }
        return null
      }
      return null
    }
  )
