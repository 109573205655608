import { createSelector } from 'reselect'

export const stateSelector = state => state.api.post.one.contact_form

export const data = () =>
  createSelector(
    [stateSelector],
    postOneContactFormState => {
      if (postOneContactFormState.data) {
        return postOneContactFormState.data
      }
      return null
    }
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    postOneContactFormState => postOneContactFormState.isLoading
  )
