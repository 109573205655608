import mergeWith from 'lodash.mergewith'

function customizer(_objValue, srcValue) {
  if (Array.isArray(srcValue)) {
    return srcValue
  }
  return undefined
}

export const deepMerge = (object, objectNew) => {
  return mergeWith({}, object, objectNew, customizer)
}

export const merge = (object, objectUpdated) => ({
  ...object,
  ...objectUpdated
})

export const oneNestedMerge = (object, objectUpdated, nested) => ({
  ...object,
  [nested]: { ...object[nested], ...objectUpdated[nested] }
})

export const addObjectInArray = (array, resource) => {
  if (!resource.type) {
    /* eslint-disable-next-line */
    console.warn(
      'You trying to add a resource from request without "type" attribute.'
    )
  }
  return [...array, resource]
}

export const updateObjectInArray = (array, resource) => {
  if (!resource.type) {
    /* eslint-disable-next-line */
    console.warn(
      'You trying to update a resource from request without "type" attribute.'
    )
  }
  const { id } = resource

  return array.map(item => {
    if (item.id !== id) {
      return item
    }
    return deepMerge(item, resource)
  })
}

export const updateOrAddObjectInArray = (array, resource) => {
  if (!resource.id) {
    throw new Error(
      'You trying to update or add a resource without "id" attribute.'
    )
  }
  if (array[0] && array[0].type && resource.type !== array[0].type) {
    throw new Error(
      'Your trying to update or add a resource without "type" attribute or with attribute type different than the aimed data store. This behavior is not permitted for consistency api store reason !'
    )
  }
  const { id } = resource
  const isResourcePresent = array.find(item => item.id === id)

  return isResourcePresent
    ? updateObjectInArray(array, resource)
    : addObjectInArray(array, resource)
}

export const updateOrAddArrayOrObjectInArray = (oldData, newData) => {
  const newDataIsArray = Array.isArray(newData)
  const oldDataIsArray = Array.isArray(oldData)

  if (newDataIsArray) {
    if (!oldDataIsArray) {
      throw new Error(
        'You cannot update or add multiple resources to an non Array aimed data'
      )
    }
    if (newData.length <= 0) {
      throw new Error(
        'You cannot update or add multiple resources to an initial empty Array aimed data'
      )
    }
    return newData.reduce((acc, actualResource) => {
      return updateOrAddObjectInArray(acc, actualResource)
    }, oldData)
  }
  return oldDataIsArray
    ? updateOrAddObjectInArray(oldData, newData)
    : deepMerge(oldData, newData)
}

export const removeObjectInArray = (array, resource) => {
  const { id } = resource

  if (!resource.id) {
    throw new Error('You trying to delete a resource without "id" attribute.')
  }
  if (!array[0]) {
    /* eslint-disable-next-line */
    console.warn('Your trying to delete a resource in a empty request store !')
  }
  if (!array[0].id) {
    /* eslint-disable-next-line */
    console.warn(
      'You trying to delete a resource in an array without any object.id or which is not conformed to JSON_API'
    )
  }
  const indexResource = array.findIndex(item => item.id === id)

  if (indexResource !== -1) {
    return [...array.slice(0, indexResource), ...array.slice(indexResource + 1)]
  }
  throw new Error(
    `You trying to delete a unfound ressource. No resource with id "${id}" is present in state`
  )
}
