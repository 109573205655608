import { createSelector } from 'reselect'

export const stateSelector = state => state.api.post.one.meetings

export const data = () =>
  createSelector(
    [stateSelector],
    postOneMeetingState => {
      if (postOneMeetingState.data) {
        return postOneMeetingState.data
      }
      return null
    }
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    postOneMeetingState => postOneMeetingState.isLoading
  )
