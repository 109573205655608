import { createSelector } from 'reselect'

export const stateSelector = state => state.api.get.all.filter_steps

export const data = () =>
  createSelector(
    [stateSelector],
    state => state.data
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    state => state.isLoading
  )

export const fieldNamePropsSelector = (_, props) => props.fieldName

export const questionByFieldName = () =>
  createSelector(
    [data(), fieldNamePropsSelector],
    (profileQuestionsData, fieldName) => {
      if (profileQuestionsData && fieldName) {
        return profileQuestionsData.find(
          question => question.attributes.field_name === fieldName
        )
      }
      return null
    }
  )

export const questionTitleAndAnswersBody = () =>
  createSelector(
    [questionByFieldName()],
    questionSelected => {
      if (questionSelected) {
        // eslint-disable-next-line max-len
        const answerBodys = questionSelected.relationships.profile_answers.data.reduce(
          (allBodyAnswersArray, currentAnswer) => {
            allBodyAnswersArray.push(currentAnswer.attributes)
            return allBodyAnswersArray
          },
          []
        )
        const { title } = questionSelected.attributes

        return {
          answer_body: answerBodys,
          title
        }
      }
      return null
    }
  )
