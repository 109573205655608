import { createSelector } from 'reselect'

export const filteredPresentersState = state => state.api.get.all.filtered_presenters

export const data = () =>
  createSelector(
    [filteredPresentersState],
    presentersData => presentersData.data
  )

export const isLoading = () =>
  createSelector(
    [filteredPresentersState],
    presentersData => presentersData.isLoading
  )


