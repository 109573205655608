export default class LocalStorage {
  static load(item) {
    if (item && typeof item === 'string') {
      try {
        const serializeItem = localStorage.getItem(item)

        return serializeItem || false
      } catch (e) {
        throw new Error(e)
      }
    }
    return false
  }

  static save(key, value) {
    if (value && typeof value === 'string') {
      try {
        localStorage.setItem(key, value)
        return true
      } catch (e) {
        throw new Error(e)
      }
    }
    return false
  }

  static delete(item) {
    if (item && typeof item === 'string') {
      try {
        localStorage.removeItem(item)
        return true
      } catch (e) {
        throw new Error(e)
      }
    }
    return false
  }
}
