import { createSelector } from 'reselect'

export const stateSelector = state => state.api.get.all.faqs

export const data = () =>
  createSelector([stateSelector], faqsState => faqsState.data)

export const isLoading = () =>
  createSelector([stateSelector], faqsState => faqsState.isLoading)

export const formatFaq = () =>
  createSelector([stateSelector], faqsState => {
    if (faqsState.data) {
      const allFaqs = []
      const faqModify = faqsState.data

      for (let j = 0; j < faqModify.length; j += 1) {
        const faqCategory = faqModify[j].attributes.category
        const filterCategoryFaq = faqModify.filter(
          faqs => faqs.attributes.category === faqCategory
        )

        faqModify.splice(0, filterCategoryFaq.length)
        allFaqs.push(filterCategoryFaq)
      }

      console.log(allFaqs)
      return allFaqs
    }
  })
