import { createSelector } from 'reselect'

export const stateSelector = state => state.api.get.all.tags_admin

export const data = () =>
  createSelector(
    [stateSelector],
    tagsState => tagsState.data
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    tagsState => tagsState.isLoading
  )
