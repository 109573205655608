import ActionCreators from './actioncreators'
import Validate from '../validators'
import Enzyme from '.'

/**
 * Make awesome request
 * @class with the use of actions creators (redux-thunk)
 */
export default class Requests {
  /**
   * Add dynamically request path to request object
   * @function
   * @param {Object} requestObject Request object
   * @param {Object} routeConfig config.routes request endpoint
   * @param {String} pathAttributes attributes to add as arguments in path callback if is required
   * @returns {Object} request object
   */
  static addRequestPath(requestObject, routeConfig) {
    const pathAttributes = requestObject.pathAttributes || []

    if (Array.isArray(routeConfig.pathAttributes)) {
      /** run only if pathAttributes is an Array */
      let objectArguments = null

      routeConfig.pathAttributes.forEach(element => {
        if (
          Object.prototype.hasOwnProperty.call(pathAttributes, element) &&
          pathAttributes[element]
        ) {
          /** required attribute is present in attributes request object */
          objectArguments = {
            ...objectArguments,
            [element]: pathAttributes[element]
          }
        } else {
          throw new Error(`Missing attribute key "${element}" in pathAttributes.
          This is required in config.routes.${requestObject.type}.${requestObject.subType}.${requestObject.model}`)
        }
      })
      /** and return valid request attributes in path callback */
      return {
        ...requestObject,
        path: () => routeConfig.path(objectArguments)
      }
    }
    /** run on all other case (undefined, null, false, ...)
     * call path callback without attributes arguments
     * can throw only if typeof pathAttributes !== Array && path callback
     * is asking for one or multiple attributes
     */
    return {
      ...requestObject,
      path: () => routeConfig.path()
    }
  }

  /**
   * Add dynamically data pointer to request object for catching desired response.data
   * config.routes can be overrided if it's present in request object
   * if no dataPointer present in request object and config.routes, add default
   * @function
   * @param {Object} requestObject Request object
   * @param {Object} routeConfig config.routes request endpoint
   * @returns {Object} request object
   */
  static addRequestPointer(requestObject, routeConfig) {
    if (
      requestObject.dataPointer &&
      typeof requestObject.dataPointer === 'function'
    ) {
      return requestObject
    }
    if (Object.prototype.hasOwnProperty.call(routeConfig, 'dataPointer')) {
      return {
        ...requestObject,
        dataPointer: routeConfig.dataPointer
      }
    }
    return {
      ...requestObject,
      dataPointer: response => response.data.data
    }
  }

  /**
   * Dynamic generating Redux Action name based on config.routes
   * @function
   * @param {Object} requestObject Request object
   * @param {String} prefix String added at the start of the Redux Action name
   * @param {String} suffix String added at the end of the Redux Action name
   * @returns {String} request redux action name
   */
  static generateRequestActionName(requestObject, prefix = '', suffix = '') {
    let actionName = ''

    actionName = actionName.concat(prefix)
    actionName = actionName.concat(requestObject.type.toLowerCase())
    actionName = actionName.concat(Enzyme.config.separators.actionName)
    actionName = actionName.concat(requestObject.subType.toLowerCase())
    actionName = actionName.concat(Enzyme.config.separators.actionName)
    actionName = actionName.concat(requestObject.model.toLowerCase())
    actionName = actionName.concat(suffix)

    return actionName
  }

  /**
   * Autogenerated Redux Action send if request object valid
   * @function
   * @param {Object} requestObject Request object
   * @param {Boolean} validations the validation returned by Validators
   * @returns {Function<Object>} valid redux action callback
   */
  static dispatchCriticalValidationsValidCreator(requestObject, validations) {
    return () => ({
      type: Requests.generateRequestActionName(
        requestObject,
        Enzyme.config.prefix.criticalValidation.valid,
        Enzyme.config.suffix.criticalValidation.valid
      ),
      requestObject,
      validations
    })
  }

  /**
   * Autogenerated Redux Action send if request object invalid
   * @function
   * @param {Object} requestObject Request object
   * @param {String} errors the error string returned by Validators
   * @returns {Function<Object>} invalid redux action callback
   */
  static dispatchCriticalValidationsInvalidCreator(requestObject, errors) {
    return () => ({
      type: Requests.generateRequestActionName(
        requestObject,
        Enzyme.config.prefix.criticalValidation.invalid,
        Enzyme.config.suffix.criticalValidation.invalid
      ),
      requestObject,
      errors
    })
  }

  /**
   * Add dynamic critical validations dispatchs redux actions in request object
   * @function
   * @param {Object} requestObject Request object
   * @param {(Object|Boolean)} validation validations returned by Validators
   * @returns {Object} new request object
   */
  static addDispatchCriticalValidations(requestObject, validation) {
    return {
      ...requestObject,
      // eslint-disable-next-line max-len
      dispatchCriticalValidationsInvalid: Requests.dispatchCriticalValidationsInvalidCreator(
        requestObject,
        validation
      ),
      // eslint-disable-next-line max-len
      dispatchCriticalValidationsValid: Requests.dispatchCriticalValidationsValidCreator(
        requestObject,
        validation
      )
    }
  }

  /**
   * Autogenerated Redux Action send before each request
   * @function
   * @param {Object} requestObject Request object
   * @returns {Function<Object>} Redux Action
   */
  static dispatchRequestBeforeCreator(requestObject) {
    return processedRequestObject => ({
      type: Requests.generateRequestActionName(
        requestObject,
        Enzyme.config.prefix.request.before,
        Enzyme.config.suffix.request.before
      ),
      isLoading: true,
      lastUpdated: Date.now(),
      storedFrom: processedRequestObject
    })
  }

  /**
   * Autogenerated Redux Action send on success each request
   * @function
   * @param {Object} requestObject Request object
   * @returns {Function<Object>} Redux Action
   */
  static dispatchRequestSuccessCreator(requestObject) {
    return (data, prevState) => {
      const NOW = Date.now()

      return {
        type: Requests.generateRequestActionName(
          requestObject,
          Enzyme.config.prefix.request.success,
          Enzyme.config.suffix.request.success
        ),
        data,
        isLoading: false,
        lastUpdated: NOW,
        requestSpeed: NOW - prevState.lastUpdated,
        storedFrom: requestObject,
        errors: null
      }
    }
  }

  /**
   * Autogenerated Redux Action send on failed each request
   * @function
   * @param {Object} requestObject Request object
   * @param {String} model Model name of the ressource requested
   * @param {String} subType String added between request type and model in Redux Action
   * @returns {Function<Object>} Redux Action
   */
  static dispatchRequestFailedCreator(requestObject) {
    return (errors, prevState) => {
      const NOW = Date.now()

      return {
        type: Requests.generateRequestActionName(
          requestObject,
          Enzyme.config.prefix.request.failed,
          Enzyme.config.suffix.request.failed
        ),
        isLoading: false,
        lastUpdated: NOW,
        requestSpeed: NOW - prevState.lastUpdated,
        storedFrom: requestObject,
        errors
      }
    }
  }

  /**
   * Add dynamic request dispatchs redux actions in request object
   * @function
   * @param {Object} requestObject Request object
   * @param {String} model Model name of the ressource requested
   * @param {String} subType String added between request type and model in Redux Action
   * @returns {Object} new request object
   */
  static addRequestDefaultDispatch(requestObject) {
    return {
      dispatchBefore: Requests.dispatchRequestBeforeCreator(requestObject),
      dispatchSuccess: Requests.dispatchRequestSuccessCreator(requestObject),
      dispatchFailed: Requests.dispatchRequestFailedCreator(requestObject),
      ...requestObject
    }
  }

  static addNotificationsDefaultObject(requestObject, routeConfig) {
    if (requestObject.notifications === true) {
      return requestObject
    }
    if (requestObject.notifications === false) {
      return requestObject
    }
    if (!requestObject.notifications) {
      return {
        ...requestObject,
        notifications:
          routeConfig.notifications ||
          Enzyme.config.default.request.notifications
      }
    }
    return {
      ...requestObject,
      notifications: {
        ...routeConfig.notifications,
        ...requestObject.notifications
      }
    }
  }

  static dispatchRequestAfterSuccessDeleteCreator(requestObject) {
    const { dispatchAfterSuccessDelete } = requestObject

    if (Array.isArray(dispatchAfterSuccessDelete)) {
      return dispatchAfterSuccessDelete.reduce((allActions, action) => {
        allActions.push(data => ({
          type: Requests.generateRequestActionName(
            action,
            Enzyme.config.prefix.request.afterSuccess.delete,
            Enzyme.config.suffix.request.afterSuccess.delete
          ),
          data,
          lastUpdated: Date.now(),
          storedFrom: requestObject,
          responsePointer: action.responsePointer
            ? action.responsePointer
            : response => response,
          statePointer: action.statePointer
            ? action.statePointer
            : state => state.data
        }))
        return allActions
      }, [])
    }
    return data => ({
      type: Requests.generateRequestActionName(
        dispatchAfterSuccessDelete,
        Enzyme.config.prefix.request.afterSuccess.delete,
        Enzyme.config.suffix.request.afterSuccess.delete
      ),
      data,
      lastUpdated: Date.now(),
      storedFrom: requestObject,
      responsePointer: dispatchAfterSuccessDelete.responsePointer
        ? dispatchAfterSuccessDelete.responsePointer
        : response => response,
      statePointer: dispatchAfterSuccessDelete.statePointer
        ? dispatchAfterSuccessDelete.statePointer
        : state => state.data
    })
  }

  static dispatchRequestAfterSuccessUpdateCreator(requestObject) {
    const { dispatchAfterSuccessUpdate } = requestObject

    if (Array.isArray(dispatchAfterSuccessUpdate)) {
      return dispatchAfterSuccessUpdate.reduce((allActions, action) => {
        allActions.push(data => ({
          type: Requests.generateRequestActionName(
            action,
            Enzyme.config.prefix.request.afterSuccess.update,
            Enzyme.config.suffix.request.afterSuccess.update
          ),
          data,
          lastUpdated: Date.now(),
          storedFrom: requestObject,
          responsePointer: action.responsePointer
            ? action.responsePointer
            : response => response,
          statePointer: action.statePointer
            ? action.statePointer
            : state => state.data
        }))
        return allActions
      }, [])
    }
    return data => ({
      type: Requests.generateRequestActionName(
        dispatchAfterSuccessUpdate,
        Enzyme.config.prefix.request.afterSuccess.update,
        Enzyme.config.suffix.request.afterSuccess.update
      ),
      data,
      lastUpdated: Date.now(),
      storedFrom: requestObject,
      responsePointer: dispatchAfterSuccessUpdate.responsePointer
        ? dispatchAfterSuccessUpdate.responsePointer
        : response => response,
      statePointer: dispatchAfterSuccessUpdate.statePointer
        ? dispatchAfterSuccessUpdate.statePointer
        : state => state.data
    })
  }

  static addRequestAfterSuccessDispatch(requestObject, routeConfig) {
    const requestObjectOverride = {
      ...routeConfig,
      ...requestObject
    }
    let objectOverride = requestObjectOverride

    if (requestObjectOverride.dispatchAfterSuccessUpdate) {
      objectOverride = {
        ...objectOverride,
        // eslint-disable-next-line max-len
        dispatchAfterSuccessUpdate: Requests.dispatchRequestAfterSuccessUpdateCreator(
          requestObjectOverride
        )
      }
    }
    if (requestObjectOverride.dispatchAfterSuccessDelete) {
      objectOverride = {
        ...objectOverride,
        // eslint-disable-next-line max-len
        dispatchAfterSuccessDelete: Requests.dispatchRequestAfterSuccessDeleteCreator(
          requestObjectOverride
        )
      }
    }

    return objectOverride
  }

  /**
   * Process request object to add required value to be send
   * Here is running a process after critical validation pass
   * @param {Object} requestObject Request object
   * @returns {Object} new request object
   */
  static processRequestObject(requestObject) {
    try {
      const { type, subType, model } = requestObject
      const requestRoute = Enzyme.config.routes[type][subType][model]

      let validRequestObject = requestObject

      validRequestObject = Requests.addRequestPath(
        validRequestObject,
        requestRoute
      )
      validRequestObject = Requests.addRequestPointer(
        validRequestObject,
        requestRoute
      )
      validRequestObject = Requests.addRequestDefaultDispatch(
        validRequestObject
      )
      validRequestObject = Requests.addRequestAfterSuccessDispatch(
        validRequestObject,
        requestRoute
      )
      validRequestObject = Requests.addNotificationsDefaultObject(
        validRequestObject,
        requestRoute
      )

      return validRequestObject
    } catch (error) {
      throw new Error(
        `An error occured during processRequestObject : ${error.message}`
      )
    }
  }

  /**
   * Run request if validations passed
   * Run citical validation, add dispatch validations, process request object, send request
   * @async @function
   * @param {Object} requestObject
   * @returns {Function} An Actions Creators
   */
  static async initializeRequest(requestObject) {
    if (!Enzyme.config.validations) {
      /** process objectRequest, add path & pointer & dispatchs & ... */
      const processedObjectRequest = Requests.processRequestObject(
        requestObject
      )

      /** run async request */
      return ActionCreators.sendRequestActions(processedObjectRequest)
    }

    /** run critical validations, to make sure requestObject are valid */
    const criticalValidations = await new Validate(
      requestObject
    ).isObjectRequestValid()
    // eslint-disable-next-line max-len
    const requestObjectValidationsDone = Requests.addDispatchCriticalValidations(
      requestObject,
      criticalValidations
    )

    /** if critical validations don't pass - dispatch invalid actions and stop request */
    if (criticalValidations !== true) {
      return ActionCreators.invalidRequestActions(requestObjectValidationsDone)
    }
    /** if critical validations pass - dispatch valid actions and run request */
    ActionCreators.validRequestActions(requestObjectValidationsDone)

    /** process objectRequest, add path & pointer & dispatchs & ... */
    const processedObjectRequest = Requests.processRequestObject(
      requestObjectValidationsDone
    )

    /** run async request */
    return ActionCreators.sendRequestActions(processedObjectRequest)
  }

  /**
   * Process request object to normalize options
   * Here is running a process before anything !
   * @param {Object} requestObject Request object
   * @returns {Object} new request object
   */
  static normalizeObject(requestObject) {
    return {
      ...requestObject,
      type: requestObject.type.toLowerCase(),
      subType: requestObject.subType.toLowerCase(),
      model: requestObject.model.toLowerCase()
    }
  }

  /**
   * Make one async api call
   * pass validations, dispatch automatique actions and return Promise
   * @function
   * @param {Object} requestObject request object
   * @param {String} requestObject.type type of the request (first key level tree)
   * @param {String} requestObject.subType subType of the request (second key level tree)
   * @param {String} requestObject.model ressources targeted as model (third key level tree)
   * @param {Object} [requestObject.pathAttributes] attributes to be added as path callback arguments
   * @param {Object} [requestsObject.data] data to be send in the body of the request
   * @param {Object} [requestsObject.params] params (query url) to be added in the request path
   * @param {Function} [requestObject.dataPointer] override default route config dataPointer
   * @param {Function} [requestObject.dispatchBefore] override default dispatchBefore request
   * @param {Function} [requestObject.dispatchSuccess] override default dispatchSuccess request
   * @param {Function} [requestObject.dispatchFailed] override default dispatchFailed request
   * @param {Object} [requestObject.axios] override default axios config (see axios doc)
   * @returns {Promise} the request result
   */
  static oneCall(requestObject) {
    return Requests.initializeRequest(Requests.normalizeObject(requestObject))
  }

  static createEnzyme(enzyme) {
    const normalizedEnzyme = Requests.normalizeObject(enzyme)

    return () => Requests.initializeRequest(normalizedEnzyme)
  }

  /**
   * Compile new Array with passing as argument request object
   * Make Array of Promise request
   * @param {Object[]} requestsObject Array of object request
   * @returns {Promise<Array>} new Promise
   */
  static requestMultipleOrInvalidActionCreators(requestsObject) {
    const dispatchs = []

    /** add all request object wrapped in asynchrone dispatch function to a new array */
    requestsObject.forEach(request => {
      dispatchs.push(Requests.oneCall(request))
    })
    /** return one promise for all requests */
    return Promise.all(dispatchs)
  }

  /**
   * Make multiple async api call in parallel
   * pass validations, dispatch automatique actions and return Promises array
   * @function
   * @param {Object[]} requestsObject request object
   * @param {String} requestsObject[].type type of the request (first key level tree)
   * @param {String} requestsObject[].subType subType of the request (second key level tree)
   * @param {String} requestsObject[].model ressources targeted as model (third key level tree)
   * @param {Object} [requestsObject[].pathAttributes] attributes to be added as path callback arguments
   * @param {Object} [requestsObject[].data] data to be send in the body of the request
   * @param {Object} [requestsObject[].params] params (query url) to be added in the request path
   * @param {Function} [requestsObject[].dataPointer] override default route config dataPointer
   * @param {Function} [requestsObject[].dispatchBefore] override default dispatchBefore request
   * @param {Function} [requestsObject[].dispatchSuccess] override default dispatchSuccess request
   * @param {Function} [requestsObject[].dispatchFailed] override default dispatchFailed request
   * @param {Object} [requestsObject[].axios] override default axios config (see axios doc)
   * @returns {Promise[]} all requests results as ordered Array
   */
  static megaCall(requestsObject) {
    if (!Array.isArray(requestsObject)) {
      throw new Error('Please give an Array of objects requests as argument')
    }
    return Requests.requestMultipleOrInvalidActionCreators(requestsObject)
  }

  static resetSpecific(requestObject) {
    const { prefix, suffix } = Enzyme.config

    if (!requestObject) {
      return null
    }
    if (requestObject.type && requestObject.subType && requestObject.model) {
      return Enzyme.store.dispatch({
        type: Requests.generateRequestActionName(
          requestObject,
          prefix.reset.specific,
          suffix.reset.specific
        )
      })
    }
    throw new Error(
      'Please send a valid requestObject object with: type, subType, and model keys.'
    )
  }

  static resetExcept(requestObject) {
    const { typesRequests, routes } = Enzyme.config
    const { type: roType, subType: roSubType, model: roModel } = requestObject

    typesRequests.forEach(type => {
      const lowercaseType = type.toLowerCase()

      Object.keys(routes[lowercaseType]).forEach(subType => {
        Object.keys(routes[lowercaseType][subType]).forEach(model => {
          if (model === roModel) {
            if (lowercaseType === roType && subType === roSubType) {
              return null
            }
          }
          return Requests.resetSpecific({
            type,
            subType,
            model
          })
        })
      })
    })
  }

  static reset(requestObject) {
    const { prefix, suffix } = Enzyme.config

    if (!requestObject) {
      return Enzyme.store.dispatch({
        type: `${prefix.reset.all}${suffix.reset.all}`
      })
    }
    if (Array.isArray(requestObject)) {
      return requestObject.forEach(object => {
        Requests.resetSpecific(object)
      })
    }
    return Requests.resetSpecific(requestObject)
  }
}
