import { combineReducers } from 'redux'
import {
  removeObjectInArray,
  updateOrAddArrayOrObjectInArray
} from './helpers/immutabilities'

/**
 * Reducers Creators based on config
 * @param {String} requestType the type of the request (first level key tree)
 * @param {String} requestSubType the subType of the request (second level key tree)
 * @param {String} modelName the ressources model name of the request (third level key tree)
 */
function createRequestReducer(
  requestType,
  requestSubType,
  modelName = '',
  object
) {
  const {
    initialRequestState,
    immutableStoresSubTypes,
    prefix, 
    suffix,
    separators
  } = object

  const REQUEST_TYPE = requestType.toLowerCase()
  const REQUEST_SUB_TYPE = requestSubType.toLowerCase()
  const MODEL = modelName.toLowerCase()
  const SEPARATOR_AN = separators.actionName

  const storeIsArray = immutableStoresSubTypes.find(
    subTypes => subTypes.toLowerCase() === REQUEST_SUB_TYPE
  )
  const defaultDataAction = storeIsArray ? [] : {}

  return (state = initialRequestState, action) => {
    switch (action.type) {
      case `${prefix.request.before}${REQUEST_TYPE}${SEPARATOR_AN}${REQUEST_SUB_TYPE}${SEPARATOR_AN}${MODEL}${suffix.request.before}`:
        return {
          ...state,
          isLoading: action.isLoading,
          lastUpdated: action.lastUpdated,
          lastUpdatedBefore: action.lastUpdated,
          requestCountBefore: state.requestCountBefore + 1,
          storedFrom: action.storedFrom
        }
      case `${prefix.request.success}${REQUEST_TYPE}${SEPARATOR_AN}${REQUEST_SUB_TYPE}${SEPARATOR_AN}${MODEL}${suffix.request.success}`:
        const isPaginated =
          REQUEST_SUB_TYPE == 'paginated' && state.storedFrom?.params?.page > 1
        return {
          ...state,
          data: isPaginated ? state.data.concat(action.data) : action.data,
          isLoading: action.isLoading,
          lastUpdated: action.lastUpdated,
          lastUpdatedSuccess: action.lastUpdated,
          requestCountSuccess: state.requestCountSuccess + 1,
          requestSpeed: action.requestSpeed,
          storedFrom: action.storedFrom,
          errors: action.errors
        }
      case `${prefix.request.failed}${REQUEST_TYPE}${SEPARATOR_AN}${REQUEST_SUB_TYPE}${SEPARATOR_AN}${MODEL}${suffix.request.failed}`:
        return {
          ...state,
          isLoading: action.isLoading,
          lastUpdated: action.lastUpdated,
          lastUpdatedFailed: action.lastUpdated,
          requestCountFailed: state.requestCountFailed + 1,
          requestSpeed: action.requestSpeed,
          storedFrom: action.storedFrom,
          errors: action.errors
        }
      case `${prefix.request.afterSuccess.delete}${REQUEST_TYPE}${SEPARATOR_AN}${REQUEST_SUB_TYPE}${SEPARATOR_AN}${MODEL}${suffix.request.afterSuccess.delete}`:
        return {
          ...state,
          lastUpdated: action.lastUpdated,
          lastUpdatedDelete: action.lastUpdated,
          actionCountDelete: state.actionCountDelete + 1,
          storedFrom: action.storedFrom,
          data: removeObjectInArray(
            action.statePointer(state),
            action.responsePointer(
              action.data && action.data.id
                ? action.data
                : action.storedFrom.pathAttributes
            )
          )
        }
      case `${prefix.request.afterSuccess.update}${REQUEST_TYPE}${SEPARATOR_AN}${REQUEST_SUB_TYPE}${SEPARATOR_AN}${MODEL}${suffix.request.afterSuccess.update}`:
        return {
          ...state,
          lastUpdated: action.lastUpdated,
          lastUpdatedUpdate: action.lastUpdated,
          actionCountUpdate: state.actionCountUpdate + 1,
          storedFrom: action.storedFrom,
          data: updateOrAddArrayOrObjectInArray(
            action.statePointer(state) || defaultDataAction,
            action.responsePointer(action.data)
          )
        }
      case `${prefix.reset.specific}${REQUEST_TYPE}${SEPARATOR_AN}${REQUEST_SUB_TYPE}${SEPARATOR_AN}${MODEL}${suffix.reset.specific}`:
        return {
          ...initialRequestState,
          actionCountReset: state.actionCountReset + 1,
          lastUpdatedReset: Date.now()
        }
      case `${prefix.reset.all}${suffix.reset.all}`:
        return {
          ...initialRequestState,
          actionCountReset: state.actionCountReset + 1,
          lastUpdatedReset: Date.now()
        }
      default:
        return {
          ...state
        }
    }
  }
}

/**
 * Create third level key reducer
 * @function
 * @param {Object} object the config.routes
 * @param {String} firstLevelKey the first level key tree of config.routes
 * @param {String} secondLevelKey the second level key tree of config.routes
 * @returns {Function} combinceReducers(objectProcessed)
 */
function thirdLevelHighOrderReducer(object, firstLevelKey, secondLevelKey) {
  let thridLevelReducer = {}

  Object.keys(object.routes[firstLevelKey][secondLevelKey]).forEach(
    thirdLevelKey => {
      const dynamicRequestReducer = createRequestReducer(
        firstLevelKey,
        secondLevelKey,
        thirdLevelKey,
        object
      )

      thridLevelReducer = {
        ...thridLevelReducer,
        [thirdLevelKey]: dynamicRequestReducer
      }
    }
  )
  return combineReducers(thridLevelReducer)
}

/**
 * Create second level key reducer - todo refactor
 * @function
 * @param {Object} object the config.routes
 * @param {String} firstLevelKey the first level key tree of config.routes
 * @returns {Function} combinceReducers(objectProcessed)
 */
function secondLevelHighOrderReducer(object, firstLevelKey) {
  let secondLevelReducer = {}

  Object.keys(object.routes[firstLevelKey]).forEach(secondLevelKey => {
    const thirdLevel = thirdLevelHighOrderReducer(
      object,
      firstLevelKey,
      secondLevelKey
    )

    secondLevelReducer = {
      ...secondLevelReducer,
      [secondLevelKey]: thirdLevel
    }
  })
  return combineReducers(secondLevelReducer)
}

/**
 * Create first level key reducer
 * @function
 * @param {Object} object the config
 * @returns {Function} combinceReducers(objectProcessed)
 */
function firstLevelHighOrderReducer(object) {
  let firstLevelReducer = {}

  Object.keys(object.routes).forEach(firstLevelKey => {
    const secondLevel = secondLevelHighOrderReducer(object, firstLevelKey)

    firstLevelReducer = {
      ...firstLevelReducer,
      [firstLevelKey]: secondLevel
    }
  })
  return combineReducers(firstLevelReducer)
}

// eslint-disable-next-line import/no-anonymous-default-export
export default config => firstLevelHighOrderReducer(config)
