import Api from '../core'

/**
 * Validators class
 * @class Borned to validate things
 */
export default class Validators {
  constructor(obj) {
    this.arguments = obj // Request Object
    this.typesRequests = Api.config.typesRequests
  }

  /**
   * Run critical validations for request
   * @function
   * @returns {true|string} true if passed, error string if errored
   */
  isObjectRequestValid = async () => {
    const { type, subType, model } = this.arguments

    try {
      await Validators.it_must_to_be_set_and_type_of(
        type,
        'string',
        'Attribute "type"'
      )
      await Validators.it_must_equival_to_one_of_array(type, this.typesRequests)
      await Validators.it_must_have_property_and_defined(
        Api.config.routes,
        type
      )
      await Validators.it_must_to_be_set_and_type_of(
        subType,
        'string',
        'Attribute "subType"'
      )
      await Validators.it_must_have_property_and_defined(
        Api.config.routes[type],
        subType
      )
      await Validators.it_must_to_be_set_and_type_of(
        model,
        'string',
        'Attribute "model"'
      )
      await Validators.it_must_have_property_and_defined(
        Api.config.routes[type][subType],
        model
      )
      await Validators.it_must_have_property_and_defined(
        Api.config.routes[type][subType][model],
        'path'
      )
      await Validators.it_must_to_be_set_and_type_of(
        Api.config.routes[type][subType][model].path,
        'function',
        'path callback'
      )

      return true
    } catch (error) {
      return `RequestObject called is not passing critical validations: ${error.message}`
    }
  }

  // eslint-disable-next-line
  static it_must_equival_to_one_of_array(string, array) {
    return new Promise(resolve => {
      array.forEach(element => {
        if (element.toLowerCase() === string.toLowerCase()) {
          resolve(true)
        }
      })
      throw new Error(`${string} must to be equivalent to one of ${array}`)
    })
  }

  // eslint-disable-next-line
  static it_must_have_property_and_defined(obj, key) {
    return new Promise(resolve => {
      if (typeof key !== 'string') {
        throw new Error(
          `Bad second parameter typeof "${key}". Expected 'string' and given "${typeof key}"`
        )
      }
      if (!Object.prototype.hasOwnProperty.call(obj, key) || !obj[key]) {
        throw new Error(`the key propertie "${key}" can not be called in config.routes...
        Valide key are : ${Object.keys(
          obj
        )}. Check your call or your config.routes...`)
      }
      resolve(true)
    })
  }

  // eslint-disable-next-line
  static it_must_to_be_set_and_type_of(obj, type, desc) {
    return new Promise(resolve => {
      if (typeof type !== 'string') {
        throw new Error(
          `Bad second parameter typeof "${type}". Expected 'string' and given "${typeof type}"`
        )
      }
      /* eslint valid-typeof: "error" */
      if (!obj || typeof obj !== type) {
        throw new Error(
          `${desc} is required in all request object, and must to be typeof "${type}"`
        )
      }
      resolve(true)
    })
  }
}
