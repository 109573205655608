import { createSelector } from 'reselect'

export const stateSelector = state => state.api.patch.one.meeting

export const data = () =>
  createSelector(
    [stateSelector],
    patchOneMeetingState => {
      if (patchOneMeetingState.data) {
        return patchOneMeetingState.data
      }
      return null
    }
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    patchOneMeetingState => patchOneMeetingState.isLoading
  )
