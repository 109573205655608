import { createSelector } from 'reselect'
import _ from 'lodash'

export const stateSelector = state => state.api.get.all.notifications

export const data = () =>
  createSelector(
    [stateSelector],
    state => state.data
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    state => state.isLoading
  )

export const reverseData = () =>
  createSelector(
    [data()],
    notifications => notifications && _.reverse(notifications)
  )

export const dataByDate = () =>
  createSelector(
    [data()],
    notifications =>
      notifications &&
      notifications.sort(
        (a, b) =>
          new Date(b.attributes.created_at) - new Date(a.attributes.created_at)
      )
  )
