const styles = {
  errorContainer: {
    padding: '100px 0',
    textAlign: 'center',
    '& span': {
      whiteSpace: 'pre-line !important'
    }
  },
  title: {
    whiteSpace: 'pre-line'
  }
}

export default styles
