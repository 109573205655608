import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import axios from 'axios'
import { currentUserSelectors } from '@src/tools/selectors'
import { debounce } from 'lodash'
import { browserInfo } from '@helpers/browserInfo'
import withStyles from 'react-jss'
import NoSpaceText from '@CommonUi/NoSpaceText'

import styles from './ErrorViewStyle'

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
      .isRequired,
    classes: PropTypes.object,
    currentUserData: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.throttleMailService = debounce(
      this.throttleMailService.bind(this),
      500
    )
    this.state = { hasError: false, error: '', errorInfo: '' }
  }

  throttleMailService = async error => {
    const { currentUserData } = this.props
    const { API_BASE_URL } = process.env
    const url = `https://${API_BASE_URL}/api/v1/error_mailers`

    try {
      await axios.post(url, { body: error, user: currentUserData?.id })
    } catch (err) {
      console.log(err)
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo })
    let message = `Error is: ${error.message} \n In component: ${errorInfo.componentStack} \n Browser version: ${browserInfo()}`
    console.log(message)
    this.throttleMailService(message)
  }

  render() {
    const { classes } = this.props
    const { hasError } = this.state

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div className={classes.errorContainer}>
          <h1 className={classes.title}>
            {i18next.t('dashboard.errorMessage')}
          </h1>
          <NoSpaceText text={i18next.t('dashboard.contactUsPlease')} />
        </div>
      )
    }

    return this.props.children
  }
}

const mapStateToProps = state => {
  const currentUserData = currentUserSelectors.data()

  return {
    currentUserData: currentUserData(state)
  }
}

export default connect(mapStateToProps, null)(withStyles(styles)(ErrorBoundary))
