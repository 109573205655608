import { createSelector } from 'reselect'

export const stateSelector = state => state.api.post.one.forgot_password

export const data = () =>
  createSelector(
    [stateSelector],
    postOneForgotPasswordState => {
      if (postOneForgotPasswordState.data) {
        return postOneForgotPasswordState.data
      }
      return null
    }
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    postOneForgotPasswordState => postOneForgotPasswordState.isLoading
  )
