import { createSelector } from 'reselect'

export const stateSelector = state => state.api.get.all.companies

export const matchSelector = (_, props) => props.match

export const data = () =>
  createSelector(
    [stateSelector],
    companiesState => companiesState.data
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    companiesState => companiesState.isLoading
  )

export const selectTheCompany = () =>
  createSelector(
    [data()],
    companiesData => {
      if (companiesData) {
        return Array.isArray(companiesData) && companiesData.length > 0
          ? companiesData[0]
          : companiesData
      }
      return null
    }
  )

export const languagesCompany = () =>
  createSelector(
    [selectTheCompany()],
    companyData => {
      if (companyData) {
        return companyData.attributes.settings.languages
      }
      return null
    }
  )
