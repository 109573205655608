import { createSelector } from 'reselect'

export const stateSelector = state => state.api.delete.one.comments

export const data = () =>
  createSelector([stateSelector], commentState => {
    if (commentState.data) {
      return commentState.data
    }
    return null
  })

export const isLoading = () =>
  createSelector([stateSelector], commentState => commentState.isLoading)
