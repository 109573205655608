import { createSelector } from 'reselect'

export const stateSelector = state => state.api.get.one.availabilities

export const data = () =>
  createSelector(
    [stateSelector],
    availabilities => availabilities.data
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    availabilities => availabilities.isLoading
  )

export const weekDaysOpen = () =>
  createSelector(
    [data()],
    availabilities => {
      const dayOpen = []

      for (let i = 0; i < 6; i += 1) {
        dayOpen.push(
          availabilities &&
            availabilities.filter(
              availability =>
                availability.attributes.weekday === i &&
                availability.attributes.open
            )
        )
      }
      return dayOpen
    }
  )
