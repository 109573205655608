import { createSelector } from 'reselect'

export const stateSelector = state => state.api.get.one.current_user

export const data = () =>
  createSelector([stateSelector], currentUserState => currentUserState.data)

export const isLoading = () =>
  createSelector(
    [stateSelector],
    currentUserState => currentUserState.isLoading
  )

export const id = () =>
  createSelector([stateSelector], currentUserState => {
    if (currentUserState.data) {
      return currentUserState.data.id
    }
    return null
  })

export const hasCurrentProfile = () =>
  createSelector([stateSelector], currentUserState => {
    if (currentUserState.data) {
      return currentUserState.data.relationships.current_profile.data !== null
    }
    return false
  })

export const currentProfile = () =>
  createSelector([stateSelector], currentUserState => {
    if (currentUserState.data) {
      return (
        currentUserState.data.relationships &&
        currentUserState.data.relationships.current_profile.data
      )
    }
    return null
  })

export const currentProfileId = () =>
  createSelector([currentProfile()], currentProfileData => {
    if (currentProfileData) {
      return currentProfileData.id
    }
    return null
  })

export const allProfilesIds = () =>
  createSelector(
    [stateSelector, hasCurrentProfile()],
    (currentUserState, currentProfileSetuped) => {
      const participantProfilesArray =
        currentUserState.data.relationships.participant_profiles.data
      const presenterProfilesArray =
        currentUserState.data.relationships.presenter_profiles.data
      const idArray = []

      if (currentUserState.data) {
        if (currentProfileSetuped) {
          idArray.push(
            currentUserState.data.relationships.current_profile.data.id
          )
        }
        participantProfilesArray.reduce((acc, profile) => {
          acc.push(profile.id)
          return acc
        }, idArray)
        presenterProfilesArray.reduce((acc, profile) => {
          acc.push(profile.id)
          return acc
        }, idArray)
        return idArray
      }
      return null
    }
  )

export const isAdmin = () =>
  createSelector([stateSelector], currentUserState => {
    if (currentUserState.data) {
      return currentUserState.data.attributes.isAdmin
    }
    return false
  })

export const email = () =>
  createSelector([data()], currentUserData => {
    if (currentUserData) {
      return currentUserData.attributes.email || null
    }
    return null
  })

export const profileType = () =>
  createSelector([currentProfile()], profile => {
    if (profile) {
      return profile.attributes.title
    }
    return null
  })

export const nextStepProfiling = () =>
  createSelector([currentProfile()], profile => {
    if (profile) {
      return profile.attributes.next_step
    }
    return null
  })

export const avatarUrl = () =>
  createSelector([stateSelector], currentUserState => {
    if (
      currentUserState.data &&
      currentUserState.data.relationships.current_profile.data
    ) {
      return currentUserState.data.relationships.current_profile.data.attributes
        .avatar_url
    }
    return null
  })

export const firstName = () =>
  createSelector([data()], userData => {
    if (userData && userData.attributes) {
      return userData.attributes.first_name
    }
    return null
  })

export const lastName = () =>
  createSelector([data()], userData => {
    if (userData && userData.attributes) {
      return userData.attributes.last_name
    }
    return null
  })

export const currentProfileIsPresenter = () =>
  createSelector([stateSelector], currentUserState => {
    if (currentUserState.data) {
      return currentUserState.data.attributes.is_presenter
    }
    return null
  })

export const currentProgramData = () =>
  createSelector([stateSelector], currentUserState => {
    if (
      currentUserState.data &&
      currentUserState.data.relationships.current_program
    ) {
      return currentUserState.data.relationships.current_program.data
    }
    return null
  })

export const programEnrollmentsData = () =>
  createSelector([stateSelector], currentUserState => {
    if (
      currentUserState &&
      currentUserState.data &&
      currentUserState.data.relationships.program_enrollments
    ) {
      return currentUserState.data.relationships.program_enrollments.data
    }
    return null
  })
