import Loadable from 'react-loadable'

import { LoaderLoadable } from '@CommonUi/Loader'

const defaultOptions = {
  loading: LoaderLoadable,
  delay: 60,
  timeout: 10000
}

const makeLoadable = (importCallback, options) => {
  if (!importCallback) {
    throw new Error('Error in makeLoadable : importCallback is required')
  }

  const mergedOptions = {
    loader: importCallback,
    ...defaultOptions,
    ...options
  }

  return Loadable(mergedOptions)
}

export default makeLoadable
