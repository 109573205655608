import { createSelector } from 'reselect'

export const stateSelector = state =>
  state.api.get.all.feedback_questions_mentoring_relationships

export const data = () =>
  createSelector(
    [stateSelector],
    feedbackQuestionsState => feedbackQuestionsState.data
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    feedbackQuestionsState => feedbackQuestionsState.isLoading
  )

export const feedbackQuestionsPresenter = () =>
  createSelector(
    [data()],
    feedbackQuestionsData =>
      feedbackQuestionsData &&
      feedbackQuestionsData.filter(
        feedbackQuestion =>
          feedbackQuestion.attributes.role_type === 'presenter'
      )
  )

export const feedbackQuestionsParticipant = () =>
  createSelector(
    [data()],
    feedbackQuestionsData =>
      feedbackQuestionsData &&
      feedbackQuestionsData.filter(
        feedbackQuestion =>
          feedbackQuestion.attributes.role_type === 'participant'
      )
  )
