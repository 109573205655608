import Axios from 'axios'

import LocalStorage from './utils'
import { Notifications, debounce } from './plugins'

/**
 * Manage request with axios, set axios default headers
 * @class
 */
export default class Enzyme {
  /**
   * Init Enzyme class instance
   * @function
   * Add config, add class propertie, set default headers
   */
  static init({ options, store }) {
    if (Enzyme.initiated === true) {
      // eslint-disable-next-line
      console.warn(
        'Re-initialization of the Api. You are calling Api.init() with Api.initiated === true'
      )
    }
    Enzyme.config = options
    Enzyme.plugins = {}
    Enzyme.plugins.Notifications = Notifications.initClass(store.dispatch)
    Enzyme.plugins.LocalStorage = LocalStorage
    Enzyme.plugins.Axios = Axios
    Enzyme.plugins.debounce = debounce
    Enzyme.store = store
    Enzyme.headersAxios = Enzyme.headersStorage
    Enzyme.initiated = true
    return Enzyme
  }

  constructor() {
    /* Initialize every time new instance called */
    this.initInstance()
  }

  /**
   * Init New Instance
   * @function
   */
  initInstance() {
    if (Enzyme.initiated !== true) {
      throw new Error(
        'You forgot to init Api before using it. Please invoke Api.init({ options, store })'
      )
    }
    this.core = Enzyme
    return this
  }

  /**
   * Send request with axios
   * @function
   * @param {String} path
   * @param {String} method
   * @param {Object} payload
   * @param {Object} params
   * @returns {Promise} Axios promise
   */
  axiosRequest(path, method = 'get', payload = {}, params = {}, config) {
    const overRideDefaultAxiosConfig = {
      ...this.core.config.axios,
      ...config
    }

    return this.core.plugins.Axios({
      method,
      url: path,
      data: payload,
      params,
      ...overRideDefaultAxiosConfig
    })
  }
  /**
   * Invoke a Get request
   * @async
   * @function
   * @param {String} path
   * @param {Object} payload
   * @param {Object} params
   */

  async get(path = '/', payload = {}, params = {}, config) {
    const request = await this.axiosRequest(
      path,
      'get',
      payload,
      params,
      config
    )

    return request
  }

  /**
   * Invoke a Post request
   * @async
   * @function
   * @param {String} path
   * @param {Object} payload
   * @param {Object} params
   */
  async post(path = '/', payload = {}, params = {}, config) {
    const request = await this.axiosRequest(
      path,
      'post',
      payload,
      params,
      config
    )

    return request
  }

  /**
   * Invoke a Put request
   * @async
   * @function
   * @param {String} path
   * @param {Object} payload
   * @param {Object} params
   */
  async put(path = '/', payload = {}, params = {}, config) {
    const request = await this.axiosRequest(
      path,
      'put',
      payload,
      params,
      config
    )

    return request
  }

  /**
   * Invoke a Patch request
   * @async
   * @function
   * @param {String} path
   * @param {Object} payload
   * @param {Object} params
   */
  async patch(path = '/', payload = {}, params = {}, config) {
    const request = await this.axiosRequest(
      path,
      'patch',
      payload,
      params,
      config
    )

    return request
  }

  /**
   * Invoke a Delete request
   * @async
   * @function
   * @param {String} path
   * @param {Object} payload
   * @param {Object} params
   */
  async delete(path = '/', payload = {}, params = {}, config) {
    const request = await this.axiosRequest(
      path,
      'delete',
      payload,
      params,
      config
    )

    return request
  }

  static get headersStorage() {
    return Enzyme.getStorageDefaultHeaders()
  }

  static set headersStorage(headers) {
    Enzyme.setStorageDefaultHeaders(headers)
  }

  static get headersAxios() {
    return Enzyme.plugins.Axios.defaults.headers.common
  }

  static set headersAxios(headers) {
    Enzyme.setAxiosDefaultHeaders(headers)
  }

  /**
   * Load headers from local storage
   * @static
   * @function
   */
  static getStorageDefaultHeaders() {
    const headers = {}

    Enzyme.config.authHeaders.forEach(key => {
      headers[key] = Enzyme.plugins.LocalStorage.load(key)
    })
    return headers
  }

  /**
   * Delete headers in local storage
   * @static
   * @function
   */
  static deleteStorageDefaultHeaders() {
    Enzyme.config.authHeaders.forEach(key => {
      Enzyme.plugins.LocalStorage.delete(key)
    })
  }

  /**
   * Delete headers in Axios
   * @static
   * @function
   */
  static deleteAxiosDefaultHeaders() {
    Enzyme.plugins.Axios.defaults.headers.common = {}
  }

  /**
   * Save headers in local storage
   * @static
   * @function
   * @param {Object} headers
   */
  static setStorageDefaultHeaders(headers) {
    Enzyme.config.authHeaders.forEach(key => {
      if (headers[key] && headers[key].length > 0) {
        Enzyme.plugins.LocalStorage.save(key, headers[key])
      }
    })
  }

  /**
   * Set Axios default headers for all Enzyme request
   * @static
   * @function
   * @param {Object} headers
   */
  static setAxiosDefaultHeaders(headers) {
    Enzyme.config.authHeaders.forEach(key => {
      if (headers[key] && headers[key].length > 0) {
        Enzyme.plugins.Axios.defaults.headers.common[key] = headers[key]
      }
    })
  }

  static setHeaders(headers) {
    Enzyme.headersAxios = headers
    Enzyme.headersStorage = headers
  }

  static refreshHeadersFromStorage() {
    Enzyme.headersAxios = Enzyme.headersStorage
  }

  static deleteHeaders() {
    Enzyme.deleteAxiosDefaultHeaders()
    Enzyme.deleteStorageDefaultHeaders()
  }
}
