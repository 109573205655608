import { createSelector } from 'reselect'

export const stateSelector = state => state.api.get.all.feedback_questions

export const data = () =>
  createSelector(
    [stateSelector],
    feedbackQuestionsState => feedbackQuestionsState.data
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    feedbackQuestionsState => feedbackQuestionsState.isLoading
  )

export const feedbackQuestionsPresenter = () =>
  createSelector(
    [data()],
    feedbackQuestionsData =>
      feedbackQuestionsData &&
      feedbackQuestionsData.filter(
        feedbackQuestion =>
          feedbackQuestion.attributes.role_type === 'presenter'
      )
  )

export const feedbackQuestionsParticipant = () =>
  createSelector(
    [data()],
    feedbackQuestionsData =>
      feedbackQuestionsData &&
      feedbackQuestionsData.filter(
        feedbackQuestion =>
          feedbackQuestion.attributes.role_type === 'participant'
      )
  )

export const feedbackQuestionsByTypeProfile = isPresenterSelector =>
  createSelector(
    [
      data(),
      isPresenterSelector,
      feedbackQuestionsPresenter(),
      feedbackQuestionsParticipant()
    ],
    (feedbackQuestionsData, isPresenter, fqPresenter, fqParticipant) => {
      if (feedbackQuestionsData && typeof isPresenter === 'boolean') {
        const selectFq = currentUserIsPresenter =>
          currentUserIsPresenter ? fqPresenter : fqParticipant

        return {
          currentUser: selectFq(isPresenter),
          otherUser: selectFq(!isPresenter)
        }
      }
      return {
        currentUser: null,
        otherUser: null
      }
    }
  )
