import { createSelector } from 'reselect'

export const stateSelector = state => state.api.get.all.user_questions

export const data = () => createSelector([stateSelector], state => state.data)

export const isLoading = () =>
  createSelector([stateSelector], state => state.isLoading)

export const userQuestionsSignUpRequired = () =>
  createSelector(
    [data()],
    userQuestions =>
      userQuestions &&
      userQuestions.filter(
        userQuestion => userQuestion.attributes.sign_up_required
      )
  )
