import React from 'react'
import PropTypes from 'prop-types'

const NoSpaceText = props => {
  const { text } = props

  return <span dangerouslySetInnerHTML={{ __html: text }} />
}

NoSpaceText.propTypes = {
  text: PropTypes.string
}

export default NoSpaceText
