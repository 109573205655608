import { createSelector } from 'reselect'

export const stateSelector = state => state.api.get.all.online

export const data = () =>
  createSelector([stateSelector], onlineData => {
    if (onlineData) {
      return onlineData.data
    }
    return null
  })

export const isLoading = () =>
  createSelector([stateSelector], onlineData => onlineData.isLoading)
