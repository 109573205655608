import { createSelector } from 'reselect'

export const stateSelector = state => state.api.patch.one.registrations

export const data = () =>
  createSelector(
    [stateSelector],
    registrationsState => {
      if (registrationsState.data) {
        return registrationsState.data
      }
      return null
    }
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    registrationsState => registrationsState.isLoading
  )
