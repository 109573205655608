import { createSelector } from 'reselect'

export const stateSelector = state => state.api.get.one.current_profile

export const data = () =>
  createSelector(
    [stateSelector],
    currentProfile => {
      if (currentProfile.data) {
        return currentProfile.data
      }
      return null
    }
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    currentProfile => currentProfile.isLoading
  )
