/* eslint-disable no-console */
/**
 * Main entry point
 * - Load required dependencies (react, redux, react-loadable, loader)
 * - Load asynchronously the application (store, router)
 * - Init the Enzyme Api before rendering the application after onload() event
 * - Render the application
 */
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'core-js/stable'
import 'react-app-polyfill/stable'

import React from 'react'
// import { render } from 'react-dom'
import ReactDOM from 'react-dom'

import Loadable from 'react-loadable'
import { Provider } from 'react-redux'

import ReactGA from 'react-ga'
// import * as Sentry from '@sentry/browser'

import messagesfr from '@translations/fr.json'
import messagesen from '@translations/en.json'
import ErrorBoundary from '@src/components/views/ErrorView'
import ReduxEnzyme from '@libs/redux-enzyme'
import Loader from '@CommonUi/Loader'
import { Helmet } from 'react-helmet'
import localizationFr from 'moment/locale/fr'

// import changeLanguageByUrl from '@CommonUi/LanguagePart/ChangeLanguage'
import i18n from 'i18next'
import Moment from 'moment'
import Store, { apiOptions, appViews } from '@database'

import CacheBuster from './CacheBuster'

import reportWebVitals from './reportWebVitals'

if (process.env.NODE_ENV === 'production') {
  console.log = function no_console() {}
}

ReactGA.initialize('UA-80262003-1')
ReactGA.pageview('/')

i18n.init({
  lng: 'fr',
  debug: true,
  resources: {
    en: {
      translation: messagesen
    },
    fr: {
      translation: messagesfr
    }
  }
})

// changeLanguageByUrl()
Moment.locale('fr', localizationFr)

// Sentry.init({
//   environment: `${process.env.APP_ENV}_${process.env.COMPANY_NAME}`,
//   dsn: process.env.DSN
// })

// if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
//   /**
//    * Service Worker snippet
//    *
//    * Reload window
//    */
//   /* eslint-disable-next-line */

//   console.log('service worker service is available')
//   const reloadWindow = registration => {
//     if (!registration.waiting) {
//       /**
//        * Just to ensure registration.waiting is available before
//        */

//       /**
//        * calling postMessage()
//        */
//       /* eslint-disable-next-line */
//       console.log(
//         'service worker service called callback but is still waiting. Skip postMessage.',
//         registration
//       )

//       return
//     }
//     /* eslint-disable-next-line */
//     console.log(
//       'service worker service calling callback. Sending postMessage',
//       registration
//     )
//     registration.waiting.postMessage('skipWaiting')
//   }

//   const onNewServiceWorker = (registration, callback) => {
//     if (registration.waiting) {
//       /**
//        * SW is waiting to activate. Can occur if multiple clients open and
//        * one of the clients is refreshed.
//        */
//       /* eslint-disable-next-line */
//       console.log(
//         'new service worker service is waiting, calling callback',
//         registration
//       )

//       return callback()
//     }

//     const listenInstalledStateChange = () => {
//       registration.installing.addEventListener('statechange', event => {
//         /* eslint-disable-next-line */
//         console.log(
//           'service worker service state change :',
//           registration,
//           event
//         )
//         if (event.target.state === 'installed') {
//           /**
//            * A new service worker is available, inform the user
//            */
//           /* eslint-disable-next-line */
//           console.log(
//             'new service worker service installed. Ready to take the control, informe the user',
//             registration,
//             event
//           )

//           callback()
//         }
//       })
//     }

//     if (registration.installing) {
//       /* eslint-disable-next-line */
//       console.log('service worker service installing', registration)
//       return listenInstalledStateChange()
//     }
//     /**
//      * We are currently controlled so a new SW may be found...
//      */

//     /* eslint-disable-next-line */
//     console.log('service worker service is controlled by another', registration)

//     /**
//      * Add a listener in case a new SW is found,
//      */

//     return registration.addEventListener(
//       'updatefound',
//       listenInstalledStateChange
//     )
//   }

//   navigator.serviceWorker.register('/service-worker.js').then(registration => {
//     /* eslint-disable-next-line */
//     console.log('service worker service registrated', registration)
//     /**
//      * Track updates to the Service Worker.
//      */
//     if (!navigator.serviceWorker.controller) {
//       /**
//        * The window client isn't currently controlled so it's a new service
//        * worker that will activate immediately
//        */
//       /* eslint-disable-next-line */
//       console.log('new service worker loaded because no controller present')
//       return
//     }
//     /**
//      * When the user asks to refresh the UI, we'll need to reload the window
//      */
//     let preventDevToolsReloadLoop

//     navigator.serviceWorker.addEventListener('controllerchange', () => {
//       /* eslint-disable-next-line */
//       console.log('service worker service controller changed')
//       /**
//        * Ensure refresh is only called once.
//        * This works around a bug in "force update on reload".
//        */
//       if (preventDevToolsReloadLoop) {
//         return
//       }
//       preventDevToolsReloadLoop = true
//       /**
//        * TODO :
//        * - delete hard non UX refreshing the page to force update if serviceWorker new version updated, installed, and is controlled the sessions
//        * - send notifications with refresh boutton !
//        */
//       window.location.reload()
//     })
//     onNewServiceWorker(registration, () => reloadWindow(registration))
//   })
// }

const Router = Loadable({
  loader: () => import(/* webpackChunkName: "router" */ '@routes'),
  loading: Loader,
  delay: 60,
  timeout: 10000
})

// if (!process.env.APP_NAME) {
//   /* eslint-disable-next-line */
//   console.warn(
//     'process.env.APP_NAME not defined, dom element will be replace with default value "default-app"'
//   )
// }

// const APP_NAME = `${process.env.APP_NAME || 'default-app'}`
// const DOM_NODE = document.getElementById(APP_NAME)

/**
 * Init redux-enzyme Library
 */
ReduxEnzyme.init({
  options: apiOptions,
  store: Store
})

// ReactDOM.render(
//   <Provider store={Store}>
//     <div>yo</div>
//   </Provider>,
//   document.getElementById('root')
// )

const companySlogan = process.env.FRONT_COMPANY_SLOGAN || "Peer Learning Solution pour Entreprises Apprenantes."

ReactDOM.render(
  <Provider store={Store}>
    <ErrorBoundary>
      <Helmet>
        <title>
          {process.env.FRONT_COMPANY_NAME}, {companySlogan}
        </title>
      </Helmet>
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload()
          }
          return <Router views={appViews} />
        }}
      </CacheBuster>
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root')
)

// render(
//   <Provider store={Store}>
//     <Router views={appViews} />
//   </Provider>,
//   DOM_NODE
// )

/**
 * Hot module replacement snippet for local development
 * */
// if (module && module.hot) {
//   module.hot.accept('./routes', () => {
//     /* eslint-disable-next-line */
//     const NewRouter = require('./routes').default

//     render(
//       <Provider store={Store}>
//         <NewRouter views={appViews} />
//       </Provider>,
//       DOM_NODE
//     )
//   })
// }

/**
 * TODO :
 * - detect if user connected or offline
 * - send notifications to tell to user that he is not connected and stack the request (maybe ?)
 */

// if (process.env.APP_ENV === 'development') {
//   /**
//    * Debug infos on staging and development environnement
//    */
//   // eslint-disable-next-line
//   console.log('----------') // eslint-disable-next-line
//   console.log('Webpack has injected :') // eslint-disable-next-line
//   console.log('process.env.NODE_ENV', process.env.NODE_ENV) // eslint-disable-next-line
//   console.log('process.env.APP_ENV', process.env.APP_ENV) // eslint-disable-next-line
//   console.log('process.env.API_BASE_URL', process.env.API_BASE_URL) // eslint-disable-next-line
//   console.log('process.env.FRONT_DOMAIN_NAME', process.env.FRONT_DOMAIN_NAME) // eslint-disable-next-line
//   console.log('process.env.PORT', process.env.PORT) // eslint-disable-next-line
//   console.log('process.env.REPORT_URI', process.env.REPORT_URI) // eslint-disable-next-line
//   console.log('process.env.COMPANY_NAME', process.env.COMPANY_NAME) // eslint-disable-next-line
//   console.log('process.env.VERSION', process.env.VERSION) // eslint-disable-next-line
//   console.log('process.env.DSN', process.env.DSN) // eslint-disable-next-line
//   console.log('----------')
// }

// export default () => {
//   render(
//     <Provider store={Store}>
//       <Router views={appViews} />
//     </Provider>,
//     DOM_NODE
//   )
// }

reportWebVitals()
