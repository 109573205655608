import { createSelector } from 'reselect'

export const stateSelector = state =>
  state.api.delete.one.presenter_recommendations

export const data = () =>
  createSelector([stateSelector], deleteOneRecommendationState => {
    if (deleteOneRecommendationState.data) {
      return deleteOneRecommendationState.data
    }
    return null
  })

export const isLoading = () =>
  createSelector(
    [stateSelector],
    deleteOneRecommendationState => deleteOneRecommendationState.isLoading
  )
