import { createSelector } from 'reselect'

export const stateSelector = state => state.api.get.all.job_category

export const data = () =>
  createSelector(
    [stateSelector],
    state => state.data
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    state => state.isLoading
  )

export const profileAnswers = () =>
  createSelector(
    [data()],
    questions => {
      if (questions) {
        return questions.relationships.profile_answers.data
      }
      return null
    }
  )
