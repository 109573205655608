/* eslint-disable max-classes-per-file */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import './Loader.scss'

export class Loader extends PureComponent {
  static propTypes = {
    pastDelay: PropTypes.bool
  }

  static defaultProps = {
    pastDelay: true
  }

  render() {
    const { pastDelay } = this.props

    if (pastDelay) {
      return (
        <div className={`wrapper`}>
          <div className={`${process.env.APP_NAME}-loader app-loader`}>
            <span />
          </div>
        </div>
      )
    }
    return null
  }
}

export class LoaderLoadable extends PureComponent {
  constructor(props) {
    super(props)

    this.LoaderComponent = Loader
  }

  render() {
    const { LoaderComponent } = this

    return (
      <div>
        <LoaderComponent />
      </div>
    )
  }
}

export default Loader
