import { createSelector } from 'reselect'

export const stateSelector = state => state.api.get.all.charters

export const data = () =>
  createSelector(
    [stateSelector],
    chartersState => chartersState.data
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    chartersState => chartersState.isLoading
  )
