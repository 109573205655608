import { createSelector } from 'reselect'

export const stateSelector = state => state.api.get.one.validate_token

export const data = () =>
  createSelector(
    [stateSelector],
    stateToken => stateToken.data
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    stateToken => stateToken.isLoading
  )

export const lastUpdated = () =>
  createSelector(
    [stateSelector],
    stateToken => stateToken.lastUpdated
  )

export const lastReset = () =>
  createSelector(
    [stateSelector],
    stateToken => stateToken.lastReset
  )
