import { combineReducers } from 'redux'
import { reducer as notificationsReducer } from 'react-notification-system-redux'

import { createApiReducer } from '@libs/redux-enzyme'

import apiOptions from '../constants/api.options'

export default combineReducers({
  api: createApiReducer(apiOptions),
  notifications: notificationsReducer
})
