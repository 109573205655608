/**
 * *******************************
 * BRIQUE - Root - TOKEN_FREE gate
 * *******************************
 * Landing page alias url for wrapping component in TOKEN_FREE gate
 * [
 *    - Home view
 *    - Contact view
 *    - Faq view
 *    - Charter view
 * ]
 *    / \
 *  /  ! \
 * --------
 * Never user fallback here if you set ROOT_VIEW at first index in the base views array
 */
export const ROOT_VIEW = 'ROOT_VIEW'
/* implicite nested routes */
export const HOME_VIEW = 'HOME_VIEW'
export const CHARTER_VIEW = 'CHARTER_VIEW'
export const REDIRECT_TO_SSO = 'REDIRECT_TO_SSO'
export const CGU_VIEW = 'CGU_VIEW'
export const FAQ_VIEW = 'FAQ_VIEW'
export const CONTACT_VIEW = 'CONTACT_VIEW'
export const DEMO_VIEW = 'DEMO_VIEW'
export const PEERMENTORING_VIEW = 'PEERMENTORING_VIEW'
export const PEERTRAINING_VIEW = 'PEERTRAINING_VIEW'
export const PEERLEARNING_FORMATION = 'PEERLEARNING_FORMATION'
export const USERAPP_DEMO_VIEW = 'USERAPP_DEMO_VIEW'
export const ADMINAPP_DEMO_VIEW = 'ADMINAPP_DEMO_VIEW'
export const ABOUT_US_VIEW = 'ABOUT_US_VIEW'
export const LEGAL_NOTICE = 'LEGAL_NOTICE'
export const FORMATION_MENTORING_ENTREPRISE = 'FORMATION_MENTORING_ENTREPRISE'
export const TARIFS = 'TARIFS'
export const SIGN_OUT_ROOT_VIEW = 'SIGN_OUT_ROOT_VIEW'
export const DEMO_AFEST_VIEW = 'DEMO_AFEST_VIEW'
export const OAUTH_LOGIN = 'OAUTH_LOGIN'

/**
 * *******************************
 * BRIQUE - Auth - AUTHENTICATED gate
 * *******************************
 * Authentification alias url for wrapping component in AUTHENTICATED gate
 * [
 *    - Sign in view
 *    - Sign up view
 *    - Forgot password view
 *    - Edit password view
 * ]
 * - in @Common
 * [
 *    - Not-found view  fallback
 * ]
 */
export const SIGN_IN_SSO_CALLBACK = 'SIGN_IN_SSO_CALLBACK'
export const SIGN_IN_FACEBOOK_CALLBACK = 'SIGN_IN_FACEBOOK_CALLBACK'
export const SIGN_IN_SAML_CALLBACK = 'SIGN_IN_SAML_CALLBACK'

export const AUTH_VIEW = 'AUTH_VIEW'
/* implicite nested routes */
export const SIGN_IN_VIEW = 'SIGN_IN_VIEW'
export const ERROR_SSO = 'ERROR_SSO'
export const SIGN_IN_FACEBOOK_VIEW = 'SIGN_IN_FACEBOOK_VIEW'
export const SIGN_IN_SAML_VIEW = 'SIGN_IN_SAML_VIEW'
export const SIGN_UP_VIEW = 'SIGN_UP_VIEW'
export const PROGRAM_SIGN_UP_VIEW = 'PROGRAM_SIGN_UP_VIEW'
export const FORGOT_PASSWORD_VIEW = 'FORGOT_PASSWORD_VIEW'
export const EDIT_PASSWORD_VIEW = 'EDIT_PASSWORD_VIEW'

/**
 * *******************************
 * BRIQUE - UserApp - PROTECTED gate
 * *******************************
 * UserApp alias url for wrapping component in PROTECTED gate
 * [
 *    - Mentoring center view
 *    - Community view
 *    - News view
 *    - Messenger view
 *    - Trainging tools view
 *    - Sign out view
 *    - Profile view
 *    - Webconferences view
 *    - Feedback view
 * ]
 * - in @Common
 * [
 *    - Settings view
 *    - Not-found view  fallback
 * ]
 */
export const USER_APP_VIEW = 'USER_APP_VIEW'
/* implicite nested routes */
export const MENTORING_CENTER_VIEW = 'MENTORING_CENTER_VIEW'
export const MENTORING_CENTER_BIS_VIEW = 'MENTORING_CENTER_BIS_VIEW'
export const COMMUNITY_VIEW = 'COMMUNITY_VIEW'
export const NEWS_VIEW = 'NEWS_VIEW'
export const MESSENGER_VIEW = 'MESSENGER_VIEW'
export const TRAINING_TOOLS_VIEW = 'TRAINING_TOOLS_VIEW'
export const PERSONAL_SETTINGS_VIEW = 'PERSONAL_SETTINGS_VIEW'
export const AVAILABILITY_SETTINGS_VIEW = 'AVAILABILITY_SETTINGS_VIEW'
/* TODO : to change component no id here */
export const PROFILE_VIEW = 'PROFILE_VIEW'
export const FEEDBACK_VIEW = 'FEEDBACK_VIEW'
export const MENTORING_RELATIONSHIPS_FEEDBACK_VIEW =
  'MENTORING_RELATIONSHIPS_FEEDBACK_VIEW'
export const WEB_CONFERENCES_VIEW = 'WEB_CONFERENCES_VIEW'
export const HELP_VIEW = 'HELP_VIEW'

/**
 * *******************************
 * BRIQUE - Admin-app - PROTECTED gate
 * *******************************
 * Admin-app alias url for wrapping component in PROTECTED gate
 * [
 *    - Users view
 *    - Dashboard view
 *    - Programs view
 * ]
 * - in @Common
 * [
 *    - Settings view
 *    - Not-found view  fallback
 * ]
 */
/* implicite nested routes */
export const USERS_VIEW = 'USERS_VIEW'
export const DASHBOARD_VIEW = 'DASHBOARD_VIEW'
export const PROGRAMS_VIEW = 'PROGRAMS_VIEW'

/**
 * *******************************
 * BRIQUE - Profiling - PROTECTED gate
 * *******************************
 * Profiling alias url for wrapping component in PROTECTED gate
 */
export const PROFILING_VIEW = 'PROFILING_VIEW'
/* implicite nested routes */
export const EDIT_USER_PROFILE_VIEW = 'EDIT_USER_PROFILE_VIEW'

/**
 * Common & fallback
 * @Common view
 */
export const NOT_FOUND_VIEW = 'NOT_FOUND_VIEW'

/**
 * *******************************
 * BRIQUE - profile-choice - PROTECTED gate
 * *******************************
 * profile-choice alias url for wrapping component in PROTECTED gate
 */
export const USER_PROFILE_CHOICE_VIEW = 'USER_PROFILE_CHOICE_VIEW'
/* implicite nested routes */
export const EDIT_USER_PROFILE_CHOOSE = 'EDIT_USER_PROFILE_CHOOSE'
