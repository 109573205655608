import ActionCable from 'actioncable'

import Enzyme from '.'

export default class Sockets {
  static initiated = false

  static init() {
    if (!Sockets.initiated) {
      const { client, uid, 'access-token': accessToken } = Enzyme.headersStorage

      // Sockets.url = `${window.location.protocol === 'https:' ? 'wss:' : 'ws:'}${
      //   Config.baseURL
      // }/cable?access-token=${accessToken}&client=${client}&uid=${uid}`

      Sockets.url = `${
        Enzyme.config.actionCable.baseURL
      }/cable?access-token=${encodeURIComponent(
        accessToken
      )}&client=${encodeURIComponent(client)}&uid=${encodeURIComponent(uid)}`
      Sockets.cable = ActionCable.createConsumer(Sockets.url)
      Sockets.initiated = true
    }
    return Sockets
  }

  static createChannel(options, controller) {
    return Sockets.cable.subscriptions.create(options, controller)
  }

  static disconnect() {
    const { cable } = Sockets
    const channels =
      cable && cable.subscriptions && Sockets.cable.subscriptions.subscriptions

    if (channels) {
      try {
        if (channels.length >= 1) {
          channels.forEach(channel => {
            Sockets.cable.subscriptions.remove(channel)
          })
        }
        Sockets.cable.disconnect()
        Sockets.initiated = false
        return true
      } catch (e) {
        throw new Error('An error occured during Socket disconnect:', e)
      }
    }
  }

  constructor() {
    Sockets.init()
    return Sockets
  }
}
