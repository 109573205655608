import { createSelector } from 'reselect'

export const stateSelector = state => state.api.get.all.training_tools

export const data = () =>
  createSelector(
    [stateSelector],
    toolsState => {
      if (toolsState.data) {
        return toolsState.data.sort(
          (first, second) => first.attributes.order + second.attributes.order
        )
      }
      return null
    }
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    toolsState => toolsState.isLoading
  )

export const toolsByCategory = () =>
  createSelector(
    [data()],
    allTools => {
      if (allTools) {
        const categories = [
          { title: 'video', key_traduction: 'tutorials' },
          { title: 'conference', key_traduction: 'conferences' },
          { title: 'tool', key_traduction: 'readings' }
        ]

        return categories.reduce((acc, category) => {
          const value = {
            ...acc,
            [category.title]: allTools.filter(
              tool => tool.attributes.category === category.title
            )
          }

          return value
        }, {})
      }
      return null
    }
  )
