import { createSelector } from 'reselect'

export const stateSelector = state =>
  state.api.post.one.mentoring_relationships_feedbacks

export const data = () =>
  createSelector(
    [stateSelector],
    postOneMentoringRelationshipsFeedbacksState => {
      if (postOneMentoringRelationshipsFeedbacksState.data) {
        return postOneMentoringRelationshipsFeedbacksState.data
      }
      return null
    }
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    postOneMentoringRelationshipsFeedbacksState =>
      postOneMentoringRelationshipsFeedbacksState.isLoading
  )
