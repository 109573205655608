import { createSelector } from 'reselect'

/**
 * Request|Resources state selector
 */
const stateSelector = state => state.api.get.all.presenters
/**
 * Generic selectors
 */
export const data = () =>
  createSelector([stateSelector], presentersData => presentersData.data)

export const isLoading = () =>
  createSelector([stateSelector], presentersData => presentersData.isLoading)

export const allPresentersTitles = () =>
  createSelector([data()], presentersData => {
    if (presentersData) {
      return presentersData.reduce((acc, presenter) => {
        if (!acc.includes(presenter.attributes.title)) {
          acc.push(presenter.attributes.title)
          return acc
        }
        return acc
      }, [])
    }

    return null
  })

export const filteredByTitles = () =>
  createSelector(
    [data(), allPresentersTitles()],
    (presentersData, presentersTitles) => {
      let presentersFiltered = {}

      if (presentersTitles && presentersData) {
        presentersTitles.forEach(title => {
          presentersFiltered = {
            ...presentersFiltered,
            [title]: presentersData.filter(
              presenter => presenter.attributes.title === title
            )
          }
        })
        return presentersFiltered
      }
      return null
    }
  )
