import { createSelector } from 'reselect'

export const stateSelector = state => state.api.patch.one.meetings_feedbacks

export const data = () =>
  createSelector(
    [stateSelector],
    patchOneMeetingFeedbacksState => {
      if (patchOneMeetingFeedbacksState.data) {
        return patchOneMeetingFeedbacksState.data
      }
      return null
    }
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    patchOneMeetingFeedbacksState => patchOneMeetingFeedbacksState.isLoading
  )
