import { createSelector } from 'reselect'

export const stateSelector = state => state.api.get.one.profile

export const data = () =>
  createSelector([stateSelector], oneProfileState => oneProfileState.data)

export const isLoading = () =>
  createSelector([stateSelector], oneProfileState => oneProfileState.isLoading)

export const type = () =>
  createSelector([data()], profileData => {
    if (profileData) {
      return profileData.type
    }
    return null
  })

export const profileAnswers = () =>
  createSelector([data()], profileData => {
    if (profileData) {
      return profileData.attributes.answers_details
    }
    return null
  })

export const profileAnswersArray = stepsAllProfileQuestions =>
  createSelector(
    [stepsAllProfileQuestions, profileAnswers()],
    (allProfileQuestions, profileAnswersObject) => {
      if (allProfileQuestions && profileAnswersObject) {
        return Object.keys(profileAnswersObject).reduce(
          (accProfileQuestions, fieldName) => {
            if (fieldName === 'hierarchy_level' || fieldName === 'worksite') {
              return accProfileQuestions
            }
            const relatedProfileQuestion = allProfileQuestions.find(
              profileQuestion =>
                profileQuestion.attributes.field_name === fieldName
            )

            if (!relatedProfileQuestion) {
              return accProfileQuestions
            }
            const relatedAnswerCodes = profileAnswersObject[fieldName]
            let relatedAnswers

            if (typeof relatedAnswerCodes === 'object') {
              relatedAnswers = relatedAnswerCodes.reduce(
                (accAnswerCode, answerCode) => {
                  accAnswerCode.push(
                    relatedProfileQuestion.attributes.profile_answers.find(
                      profileAnswer =>
                        profileAnswer.attributes.code === answerCode.code
                    )
                  )
                  return accAnswerCode
                },
                []
              )
            } else if (typeof relatedAnswerCodes === 'string') {
              relatedAnswers = [0]
            }

            accProfileQuestions.push({
              question: relatedProfileQuestion,
              answers: relatedAnswers
            })
            return accProfileQuestions
          },
          []
        )
      }
      return null
    }
  )
