let BROWSER_LIST = {
  Firefox: 'Mozilla Firefox',
  SamsungBrowser: 'Samsung Internet',
  Opera: 'Opera',
  OPR: 'Opera',
  Trident: 'Microsoft Internet Explorer',
  Edge: 'Microsoft Edge',
  Chrome: 'Google Chrome or Chromium',
  Safari: 'Apple Safari'
}

export const browserInfo = () => {
  let sBrowser,
    sUsrAg = navigator.userAgent

  // The order matters here, and this may report false positives for unlisted browsers.
  // eslint-disable-next-line array-callback-return
  sBrowser = Object.keys(BROWSER_LIST).find(key => {
    if (sUsrAg.indexOf(key) > -1) {
      return BROWSER_LIST[key]
    }
  })
  return sBrowser || 'unknown'
}
