import { createSelector } from 'reselect'

export const stateSelector = state => state.api.post.one.registrations

export const data = () =>
  createSelector(
    [stateSelector],
    postOneRegistrationsState => {
      if (postOneRegistrationsState.data) {
        return postOneRegistrationsState.data
      }
      return null
    }
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    postOneRegistrationsState => postOneRegistrationsState.isLoading
  )
