import React from 'react'
import NotifySystem from 'react-notification-system-redux'
import i18next from 'i18next'

/**
 * Manage request responses based on axios promise
 * @class
 */
export default class Notifications {
  static messages = {
    nothing: 'There is no notification to send',
    status: 'Status is required in the response',
    response: 'Notifications needs a response'
  }

  static status = [0, 200, 201, 400, 401, 403, 404, 422, 429, 500]

  static default = {
    0: true,
    200: false,
    201: false,
    400: true,
    401: true,
    403: true,
    404: true,
    422: true,
    429: true,
    500: true,
    503: true
  }

  static level = {
    0: 'error',
    200: 'success',
    201: 'success',
    400: 'error',
    401: 'warning',
    403: 'warning',
    404: 'info',
    422: 'warning',
    429: 'warning',
    500: 'error',
    503: 'info'
  }

  static config = {
    0: message => ({
      title: 'Timeout',
      message,
      autoDismiss: 5
    }),
    200: {
      title: 'Request OK',
      message: 'The request status is OK',
      autoDismiss: 3
    },
    201: {
      title: 'Resource created',
      message: 'The resource was created successfully',
      autoDismiss: 3
    },
    400: {
      title: 'Bad Request',
      message: 'A bad request occured, please contact your CIO/DSI',
      autoDismiss: 3,
      action: {
        label: 'Contact now'
        // callback: () => console.log('todo history.push contact component')
      }
    },
    401: messages => ({
      title: 'Unauthorized',
      children: (
        <div className='notification-message'>
          {messages.map((message, index) => (
            <span key={index} style={{ display: 'block' }}>
              {message}
            </span>
          ))}
        </div>
      ),
      autoDismiss: 6
    }),
    403: {
      title: 'Not Permitted',
      message: 'You do not have permission to do this action',
      autoDismiss: 3
    },
    404: {
      title: 'Not Found',
      message: 'This ressource does not exist',
      autoDismiss: 2
    },
    422: messages => ({
      title: i18next.t('errors.unprocessable_entity'),
      children: (
        <div className='notification-message'>
          {messages?.map((message, index) => (
            <span key={index} style={{ display: 'block' }}>
              {message}
            </span>
          ))}
        </div>
      ),
      autoDismiss: 6
    }),
    429: {
      title: 'Too many requests',
      message: 'You send too many requests to the server',
      autoDismiss: 5 // ,
      // action: {
      //   label: 'TODO remake request CTA'
      /**
       * TODO give access to inital request from notification
       */
      //   callback: () => alert('REMAKING REQUEST')
      // }
    },
    500: {
      title: 'Internal Server Error',
      message:
        'The server is down, please try again later or contact and inform your CIO/DSI',
      autoDismiss: 5,
      action: {
        label: 'Contact now'
        // callback: () => console.log('todo history push contact')
      }
    },
    503: {
      title: 'Service Unavailable',
      message: 'The server is unavailable at this moment. Please try later',
      autoDismiss: 5
    }
  }

  static initClass(dispatch) {
    Notifications.engine = NotifySystem
    Notifications.dispatch = dispatch
    Notifications.initiated = true
    return Notifications
  }

  /**
   * Process options
   * @function
   */
  static makeOption(status, options) {
    if (!status) {
      throw new Error(Notifications.messages.status)
    }
    if (!options) {
      return Notifications.default[status]
    }
    return options[status] === false || options[status]
      ? options[status]
      : Notifications.default[status]
  }

  /**
   * Process notification redux action
   * @function
   */
  static makeNotification(status, options, response) {
    return Notifications[status](options, response)
  }

  /**
   * Process notificationLevel
   * @function
   */
  static makeLevel(status, options) {
    if (!status) {
      throw new Error(Notifications.messages.status)
    }
    if (!options) {
      return Notifications.level[status]
    }
    return options.level || Notifications.level[status]
  }

  static some(callback) {
    return Notifications.status.some(value => callback(value))
  }

  constructor({ response, ...options }) {
    /**
     * Checks
     */
    if (!response) {
      throw new Error(Notifications.messages.response)
    }
    if (!response.status && response.status !== 0) {
      throw new Error(Notifications.messages.status)
    }

    /**
     * Init Instance with all status configured
     */
    Notifications.some(status => {
      if (status === parseInt(response.status, 10)) {
        this.status = response.status.toString()
        this.options = Notifications.makeOption(this.status, options)
        this.notification = Notifications.makeNotification(
          this.status,
          this.options,
          response
        )
        this.level = Notifications.makeLevel(this.status, this.options)
        return true
      }
      return false
    })
    return this
  }

  send = () => {
    if (!this.notification || !this.level) {
      return Notifications.messages.nothing
    }
    if (
      !Notifications.dispatch &&
      typeof Notifications.dispatch === 'function'
    ) {
      throw new Error('Argument dispatch is required and is typeof "function"')
    }
    return Notifications.dispatch(
      Notifications.engine[this.level](this.notification)
    )
  }

  /**
   * Process notification object for timeout
   * @function
   * @param {object} timeoutConfig the timeout notification config to override default
   * * @param {object} response the response of the request
   * @returns {object|boolean} the notification object or false if timeoutConfig undefined
   */
  static 0(timeoutConfig, response) {
    if (!timeoutConfig) {
      return false
    }
    if (timeoutConfig === true) {
      return Notifications.config['0'](response.message)
    }
    return {
      ...Notifications.config['0'](response.message),
      ...timeoutConfig
    }
  }

  /**
   * Process notification object for ok status
   * @function
   * @param {object} okConfig the ok notification config to override default
   * @returns {object|boolean} the notification object or false if okConfig undefined
   */
  static 200(okConfig) {
    if (!okConfig) {
      return false
    }
    if (okConfig === true) {
      return Notifications.config['200']
    }
    return {
      ...Notifications.config['200'],
      ...okConfig
    }
  }

  /**
   * Process notification object for created status
   * @function
   * @param {object} okConfig the created notification config to override default
   * @returns {object|boolean} the notification object or false if createdConfig undefined
   */
  static 201(createdConfig) {
    if (!createdConfig) {
      return false
    }
    if (createdConfig === true) {
      return Notifications.config['201']
    }
    return {
      ...Notifications.config['201'],
      ...createdConfig
    }
  }

  /**
   * Process notification object for bad request status
   * @function
   * @param {object} badRequestConfig the bad request notification config to override default
   * @returns {object|boolean} the notification object or false if badRequestConfig undefined
   */
  static 400(badRequestConfig) {
    if (!badRequestConfig) {
      return false
    }
    if (badRequestConfig === true) {
      return Notifications.config['400']
    }
    return {
      ...Notifications.config['400'],
      ...badRequestConfig
    }
  }

  /**
   * Process notification object for unauthorized status
   * @function
   * @param {object} unauthorizedConfig the unauthorized notification config to override default
   * @returns {object|boolean} the notification object or false if unauthorizedConfig undefined
   */
  static 401(unprocessableEntityConfig, response) {
    if (!unprocessableEntityConfig) {
      return false
    }
    let messages

    if (response.data.errors.full_messages) {
      messages = response.data.errors.full_messages
    }
    if (Array.isArray(response.data.errors)) {
      messages = response.data.errors.reduce((acc, error) => {
        if (error.detail) {
          acc.push(error.detail)
        } else if (typeof error === 'string') {
          acc.push(error)
        }
        return acc
      }, [])
    }
    if (unprocessableEntityConfig === true) {
      return Notifications.config['422'](messages)
    }
    return {
      ...Notifications.config['422'](messages),
      ...unprocessableEntityConfig
    }
  }

  /**
   * Process notification object for forbidden status
   * @function
   * @param {object} forbiddenConfig the forbidden notification config to override default
   * @returns {object|boolean} the notification object or false if forbiddenConfig undefined
   */
  static 403(forbiddenConfig) {
    if (!forbiddenConfig) {
      return false
    }
    if (forbiddenConfig === true) {
      return Notifications.config['403']
    }
    return {
      ...Notifications.config['403'],
      ...forbiddenConfig
    }
  }

  /**
   * Process notification object for not found status
   * @function
   * @param {object} notFoundConfig the not found notification config to override default
   * @returns {object|boolean} the notification object or false if notFoundConfig undefined
   */
  static 404(notFoundConfig) {
    if (!notFoundConfig) {
      return false
    }
    if (notFoundConfig === true) {
      return Notifications.config['404']
    }
    return {
      ...Notifications.config['404'],
      ...notFoundConfig
    }
  }

  /**
   * Process notification object for unprocessable entity status
   * @function
   * @param {object} unprocessableEntityConfig the unprocessable entity notification config to override default
   * @param {object} response the response of the request
   * @returns {object|boolean} the notification object or false if unprocessableEntityConfig undefined
   */
  static 422(unprocessableEntityConfig, response) {
    if (!unprocessableEntityConfig) {
      return false
    }
    let messages

    if (response.data.errors.full_messages) {
      messages = response.data.errors.full_messages
    }
    if (Array.isArray(response.data.errors)) {
      messages = response.data.errors.reduce((acc, error) => {
        if (error.detail) {
          acc.push(error.detail)
        } else if (typeof error === 'string') {
          acc.push(error)
        }
        return acc
      }, [])
    }
    if (unprocessableEntityConfig === true) {
      return Notifications.config['422'](messages)
    }
    return {
      ...Notifications.config['422'](messages),
      ...unprocessableEntityConfig
    }
  }

  /**
   * Process notification object for internal server error status
   * @function
   * @param {object} internalServerErrorConfig the internal server error notification config to override default
   * @returns {object|boolean} the notification object or false if internalServerErrorConfig undefined
   */
  static 429(internalServerErrorConfig) {
    if (!internalServerErrorConfig) {
      return false
    }
    if (internalServerErrorConfig === true) {
      return Notifications.config['429']
    }
    return {
      ...Notifications.config['429'],
      ...internalServerErrorConfig
    }
  }

  /**
   * Process notification object for internal server error status
   * @function
   * @param {object} internalServerErrorConfig the internal server error notification config to override default
   * @returns {object|boolean} the notification object or false if internalServerErrorConfig undefined
   */
  static 500(internalServerErrorConfig) {
    if (!internalServerErrorConfig) {
      return false
    }
    if (internalServerErrorConfig === true) {
      return Notifications.config['500']
    }
    return {
      ...Notifications.config['500'],
      ...internalServerErrorConfig
    }
  }

  /**
   * Process notification object for service unavailable error status
   * @function
   * @param {object} serviceUnavailable the service unavailable error notification config to override default
   * @returns {object|boolean} the notification object or false if serviceUnavailable undefined
   */
  static 503(serviceUnavailable) {
    if (!serviceUnavailable) {
      return false
    }
    if (serviceUnavailable === true) {
      return Notifications.config['503']
    }
    return {
      ...Notifications.config['503'],
      ...serviceUnavailable
    }
  }
}
