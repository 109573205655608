import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createLogger } from 'redux-logger'

import { ApiMiddleware } from '@libs/redux-enzyme'

import ApiOptions from './constants/api.options'
import AppViews from './constants/views'
import rootReducer from './reducers'

export const apiOptions = ApiOptions
export const appViews = AppViews

const middlewares = [thunk, ApiMiddleware]

function addMiddleware(middleware) {
  middlewares.push(middleware)
}

if (process.env.APP_ENV !== 'production') {
  const logger = createLogger({
    collapsed: false,
    duration: true,
    timestamp: true,
    logErrors: true
  })

  addMiddleware(logger)
}

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
)

export default store
