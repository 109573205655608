import { createSelector } from 'reselect'

export const stateSelector = state => state.api.put.one.edit_password

export const data = () =>
  createSelector(
    [stateSelector],
    putOneEditPasswordState => {
      if (putOneEditPasswordState.data) {
        return putOneEditPasswordState.data
      }
      return null
    }
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    putOneEditPasswordState => putOneEditPasswordState.isLoading
  )
