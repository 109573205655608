import { createSelector } from 'reselect'

export const stateSelector = state => state.api.post.one.meetings_feedbacks

export const data = () =>
  createSelector(
    [stateSelector],
    postOneMeetingFeedbacksState => {
      if (postOneMeetingFeedbacksState.data) {
        return postOneMeetingFeedbacksState.data
      }
      return null
    }
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    postOneMeetingFeedbacksState => postOneMeetingFeedbacksState.isLoading
  )
