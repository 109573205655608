import { createSelector } from 'reselect'

export const stateSelector = state => state.api.get.all.articles

export const data = () =>
  createSelector(
    [stateSelector],
    state => state.data
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    state => state.isLoading
  )

export const dataOrdered = () =>
  createSelector(
    [data()],
    articles => {
      if (articles) {
        return articles
          .sort(
            (first, second) =>
              -(
                new Date(first.attributes.created_at) -
                new Date(second.attributes.created_at)
              )
          )
          .slice(0, 50)
      }
      return null
    }
  )
